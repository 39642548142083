import React, { useEffect } from 'react';
import './LandslideProbabilityView.css';
import { useSelector } from 'react-redux';
import {landslideSideResult } from 'store/wasmApplication/wasmApplicationResult.slice';
import { RootState } from 'store';
import GroupedBarChart, { IGroupedData } from 'components/charts/GroupedBarChart/GroupedBarChart';
import Table from 'react-bootstrap/Table';


interface ILandslideGroups {
  random_veg: number,
  random_no_veg: number,
  security_veg: number,
  security_no_veg: number
}

const labels = { x: "Area (m²)", y: "Landslide Probability (%)" };
const legend = [ 
  {name: "Random No Vegetation", is_visible: true},
  {name: "Random Vegetation", is_visible: true},
  {name: "Deterministic No Vegetation", is_visible: false},
  {name: "Deterministic Vegetation", is_visible: false},
];

function LandslideProbabilityView() {

  const results = useSelector((state: RootState) => state.WASMApplicationResult);
  const nLandslides = useSelector((state: RootState) => state.WASMApplication.nLandslides);

  const [ data_grouped, set_data_grouped ] = React.useState<IGroupedData[]>([]); 
  
  const [ total_failed_non_veg, set_total_failed_non_veg] = React.useState(0);
  const [ total_failed_veg, set_total_failed_veg] = React.useState(0);

  useEffect(() => {

    // bin the results
    const bins: Record<string, ILandslideGroups> = {}

    let total_failed_veg_local = 0;
    let total_failed_non_veg_local = 0;

    function process_landslide(ls: landslideSideResult, ls_type: "security" | "random") {
      const area = ls.area;
  
      if (isNaN(area)) {
        console.warn("Area is NaN!");
        return;
      }
  
      const bin_size = 100;
      const bin = Math.floor(area / bin_size) * bin_size;
      if (!(bin in bins)) {
        bins[bin] = {
          random_veg: 0,
          random_no_veg: 0,
          security_veg: 0,
          security_no_veg: 0
        };
      }

      // It is considered failed if it is less than 1
      if (ls.FOSVeg < 1) {
        bins[bin][ls_type + "_veg" as keyof ILandslideGroups] += 1;
      }
      if (ls.FOSNoVeg < 1) {
        bins[bin][ls_type + "_no_veg" as keyof ILandslideGroups] += 1;
      }

      if (ls_type === "random") {
        if (ls.FOSVeg < 1) {
          total_failed_veg_local += 1;
        }
        if (ls.FOSNoVeg < 1) {
          total_failed_non_veg_local += 1;
        }
      }
    }

    /** Generate data  */
    for(let i = 0; i < results.size; i++) {
      process_landslide(results.data[i].r, "random");    
      process_landslide(results.data[i].s, "security");
    }

    set_total_failed_non_veg(total_failed_non_veg_local/nLandslides);
    set_total_failed_veg(total_failed_veg_local/nLandslides);

    for(let bin in bins) {
      for (let t of ["random", "security"]) {
        for(let s of ["_veg", "_no_veg"]) {
          if (bins[bin][t + s as keyof ILandslideGroups] === 0) {
            bins[bin][t + s as keyof ILandslideGroups] = 0;
          } else {
            bins[bin][t + s as keyof ILandslideGroups] /= results.size;
            bins[bin][t + s as keyof ILandslideGroups] *= 100;
          }
        }
      }
    }

    const data_grouped = Object.keys(bins).map((bin) => {
      return {
        label: bin, // getExponentLabel("m", "2", bin),
        values: [bins[bin].random_no_veg, bins[bin].random_veg, bins[bin].security_no_veg , bins[bin].security_veg  ]
      }
    });
    set_data_grouped(data_grouped);

  }, [results]);

  return (
    <div>
      <GroupedBarChart data={data_grouped} defaultMaxY={100} labels={labels} legend={legend}/>

      <Table>
        <thead>
          <tr>
            <th></th>
            <th>No Vegetation</th>
            <th>Vegetation</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Overall Landslide Probability</td>
            <td>{total_failed_non_veg.toFixed(2)}</td>
            <td>{total_failed_veg.toFixed(2)}</td>
          </tr>
          <tr>
            <td>Current Degree of Protection = { (((total_failed_non_veg - total_failed_veg) / (total_failed_non_veg)) *100 /* Issues 32 changes this to a percentage*/ )  .toPrecision(2) }%</td>
            <td/>
            <td/>
          </tr>
        </tbody>
      </Table>
    </div>
  );
}

export default LandslideProbabilityView;
