import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store';

export interface uiState {
  // About dialog
  modal_navbar_about_visible: boolean,

  // Processing related UI state inforamtion
  modal_processing_visible: boolean,
  processing_completed: boolean,

  // Error related UI state information
  modal_error_visible: boolean,
  modal_error_message: string,
  current_tab_name: string,

  modal_soil_thickness_fraction: boolean,
  modal_soil_thickness: boolean
}

const initialState: uiState = {
  modal_navbar_about_visible: false,
  modal_processing_visible: false,
  processing_completed: false,
  modal_error_visible: false,
  modal_error_message: "",
  current_tab_name: "Input",
  modal_soil_thickness_fraction: false,
  modal_soil_thickness: false
};

export const uiSlice = createSlice({
  name: 'UI',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    showAboutModal: (state, action: PayloadAction<boolean>) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.modal_navbar_about_visible = action.payload;
    },

    setCurrentTab: (state, action: PayloadAction<string>) => {
      state.current_tab_name = action.payload;
    },

    // When the calculation is running, update state properly
    showCalculationProgress: (state, action: PayloadAction<boolean>) => {
      state.modal_processing_visible = action.payload;
      state.processing_completed = !action.payload;
    },

    setProcessingIncomplete: (state) => {
      state.processing_completed = false;
    },

    // When an error occurs, save the error and show the state
    showErrorMessage: (state, action: PayloadAction<string>) => {
      state.modal_error_message = action.payload;
      state.modal_error_visible = true;
      state.modal_processing_visible = false;
      state.processing_completed = false;
      state.modal_navbar_about_visible = false;
      state.modal_soil_thickness_fraction = false;
      state.modal_soil_thickness = false;
    },

    hideErrorMessage: (state) => {
      state.modal_error_message = '';
      state.modal_error_visible = false;
      state.modal_processing_visible = false;
      state.processing_completed = false;
      state.modal_navbar_about_visible = false;
      state.modal_soil_thickness_fraction = false;
      state.modal_soil_thickness = false;
    },
    
    showSoilThicknessModal: (state, action: PayloadAction<boolean>) => {
      state.modal_soil_thickness = action.payload;
    },
    
    showSoilThicknessFractionModal: (state, action: PayloadAction<boolean>) => {
      state.modal_soil_thickness_fraction = action.payload;
    }


  }
});

export const { showAboutModal, showCalculationProgress, showErrorMessage, hideErrorMessage, setCurrentTab, setProcessingIncomplete, showSoilThicknessModal, showSoilThicknessFractionModal } = uiSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const getErrorMessage = (state: RootState) => state.UI.modal_error_message;

export default uiSlice.reducer;
