import { ApplicationConfig } from 'services/ApplicationConfig';
import { userDefinedValueNormal } from 'store/wasmApplication/wasmApplication.slice';
import { ResultStatus} from 'components/messages';
import createVectorDouble from 'utils/createVectorDouble';
/**
 * Create a WASM configuration class from the Web App config
 * @param module WASM Module Reference
 * @param config The application config from the web application
 * @returns A WASM config class
 */
export default function ConfigFactory(module: any, config: ApplicationConfig) {

    if(module === undefined) {
        throw Object.assign({
             type: ResultStatus.Failure,
             message: "Application not ready.",
             data: null
            });
    }

    const configClass = new module.Config()

    console.log(config)

    configClass.SetNLandslides(config.nLandslides);
    configClass.SetSlopeDeg(config.slope);
    configClass.SetAspectDeg(config.aspectDeg);
    configClass.SetElevation(config.elevation);
    configClass.SetT0(config.t0);
    configClass.SetSaturatedThicknessFraction(config.saturatedThicknessFraction);
    configClass.SetPassiveEarthPressureCorrection(config.passiveEarthPressureCorrection);
    configClass.SetInvGammaShape(config.invGammaShape);
    configClass.SetInvGammaScale(config.invGammaScale);
    configClass.SetInvGammaS(config.invGammaS);
    configClass.SetFieldCapacityPressure(config.fieldCapacityPressure);
    configClass.SetVGWetAlpha(config.vgWetAlpha/1000);
    configClass.SetVGWetN1(config.vgWetN);
    configClass.SetSoilThicknessByParts(config.soilthickness_mean, config.soilthickness_sd);
    configClass.SetMaxLandslideArea(config.maxLandslideArea);
    configClass.SetCoeffCharacteristicRootReinforcement(config.characteristicValueCoefficient);

    // Verify that treeDensity and speciesDensity are the same length
    if (config.treeDBH.length !== config.speciesDensity.length) {
        throw Object.assign({
            type:  ResultStatus.Failure,
            message: "Tree DBH and Species Percentage must have same length",
            data: null
        });
    }

    configClass.SetSpeciesDensity(   createVectorDouble(module, config.speciesDensity));
    configClass.SetTreeDBH(             createVectorDouble(module, config.treeDBH));


    function createNormalDistribution(value: userDefinedValueNormal) {
        let normal_dist = new module.UserDefinedValueNormal()
        normal_dist.SetMean(value.mean); 
        normal_dist.SetStdDev(value.stdDev);
        return normal_dist;
    }

    const cohesion = {
        mean: config.cohesion.mean *  1000,
        stdDev: config.cohesion.stdDev *  1000,
        gamma: config.cohesion.gamma
    }
    configClass.SetGammaCohesion(config.cohesion.gamma);
    configClass.SetCohesion(createNormalDistribution(cohesion));


    configClass.SetGammaDensityDry(config.densityDry.gamma);
    configClass.SetDensityDry(createNormalDistribution(config.densityDry));
    

    const frictionAngle = {
        mean: config.frictionAngle.mean * Math.PI / 180.0,
        stdDev: config.frictionAngle.stdDev * Math.PI / 180.0,
        gamma: config.frictionAngle.gamma
    }
    configClass.SetGammaFrictionAngle(config.frictionAngle.gamma);
    configClass.SetFrictionAngle(createNormalDistribution(frictionAngle));  


    configClass.SetGammaRootReinforcement(config.gammaRootReinforcement);

    
    
    
    const configOutput = configClass.toString();
    console.log(configOutput);
    return configClass;
}