

enum ResultStatus {
    Success,
    Failure,
    Unknown
}

interface ResultMessage {
    // Determine if the result is successful or not
    type: ResultStatus,

    // A human readable message
    message: string,

    // Extra information with the message
    data: any
}

export {type ResultMessage, ResultStatus };