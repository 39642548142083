function TauCOuter() {
  return (
    <p style={{ "margin": 0 }}>
    Outer User Defined &tau;<span style={{ fontSize: "0.6em", verticalAlign: "bottom" }} >c</span>
    </p>
  );
} 
function TauCInner() {
  return (
    <p style={{ "margin": 0 }}>
    Inner User Defined &tau;<span style={{ fontSize: "0.6em", verticalAlign: "bottom" }} >c</span>
    </p>
  );
} 

function Sediment(upper: string, extra?: string | null) {
  return (
    <span>
    {extra} D<span className="supsub">
        <sup>{upper}</sup>
        <sub>50</sub>
        </span> 
    </span>
  );
}


function getExponentLabel(base: string, exp: string, extra?: string) {
  return (
    <span style={{ "margin": 0 }}>
      {base}<span style={{ fontSize: "0.7em", verticalAlign: "top" }} >{exp}</span>{extra}
    </span>
  );
} 

export {TauCInner, TauCOuter, Sediment, getExponentLabel};