import {useEffect, useState} from 'react';
import './TabulatedResultsView.css';
import Table from 'react-bootstrap/Table';
import { RootState } from 'store';
import { useSelector } from 'react-redux';
import { getExponentLabel} from 'utils/labels';
import GroupedBoxPlot, { IData as BoxPlotParams, IGroupedData } from 'components/charts/GroupedBoxPlot/GroupedBoxPlot';
import { vector } from 'store/wasmApplication/wasmApplicationResult.slice';


const labels = {x: "", y: "Factor of Safety"};
const legend = { names: ["No Vegetation", "Vegetation"] };

function TabulatedResultsView() {

  const results = useSelector((state: RootState) => state.WASMApplicationResult);
  const inputs = useSelector((state: RootState) => state.WASMApplication);

  const [lateral_root_reinforcement, set_lateral_root_reinforcement] = useState("Not Calculated");  
  const [basal_root_reinforcement, set_basal_root_reinforcement] = useState("Not Calculated");

  const [tree_stand_density, set_tree_stand_density] = useState("Not Calculated");
  const [tree_stand_volume, set_tree_stand_volume] = useState("Not Calculated");
  const [vegetation_weight, set_vegetation_weight] = useState("Not Calculated");
  const [weight_in_soil_thickness, set_weight_in_soil_thickness] = useState("Not Calculated");
  const [reliability_index_veg, set_reliability_index_veg] = useState("Not Calculated");
  const [reliability_index_no_veg, set_reliability_index_no_veg] = useState("Not Calculated");

  const [cohesion, set_cohesion] = useState("Not Calculated");
  const [friction_angle, set_friction_angle] = useState("Not Calculated");

  //PSF = Partial Security Factor
  const [psf_lateral_root_reinforcement, set_psf_lateral_root_reinforcement] = useState("Not Calculated");
  const [psf_lateral_root_reinforcement_char, set_psf_lateral_root_reinforcement_char] = useState("Not Calculated");
  const [psf_basal_root_reinforcement, set_psf_basal_root_reinforcement] = useState("Not Calculated");
  const [psf_basal_root_reinforcement_char, set_psf_basal_root_reinforcement_char] = useState("Not Calculated");


  const [cohesion_char, set_cohesion_char] = useState("Not Calculated");
  const [friction_angle_char, set_friction_angle_char] = useState("Not Calculated");


  // Boxplot data 
  const [data, setData] = useState<IGroupedData[]>([]);



  /**
   * Create boxplot data for FOS
   */
  function update_fos_boxplot(results: vector) {

    const asc = (arr: number[])  => arr.sort((a, b) => a - b);

    const quantile = (arr: number[], q: number) => {
        const sorted = asc(arr);
        const pos = (sorted.length - 1) * q;
        const base = Math.floor(pos);
        const rest = pos - base;
        if (sorted[base + 1] !== undefined) {
            return sorted[base] + rest * (sorted[base + 1] - sorted[base]);
        } else {
            return sorted[base];
        }
    };

    const q25 = (arr: number[]) => quantile(arr, .25);

    const q50 = (arr: number[]) => quantile(arr, .50);

    const q75 = (arr: number[]) => quantile(arr, .75);

    const fos_no_veg = [];
    const fos_veg = [];

    for(let i = 0; i < results.size; i++) {
      fos_no_veg.push(results.data[i].r.FOSNoVeg);
      fos_veg.push(results.data[i].r.FOSVeg);
    }

    setData([{ label: "",
      values: [
       {
          q1: q25(fos_veg),
          q3: q75(fos_veg),
          max: fos_veg.reduce((a,b) => Math.max(a, b), 0),
          min: fos_veg.reduce((a,b) => Math.min(a, b), 0),
          median: q50(fos_veg),
          number: fos_veg.length
        },
        {
          q1: q25(fos_no_veg),
          q3: q75(fos_no_veg),
          max: fos_no_veg.reduce((a,b) => Math.max(a, b), 0),
          min: fos_no_veg.reduce((a,b) => Math.min(a, b), 0),
          median: q50(fos_no_veg),
          number: fos_no_veg.length
        }  
      ]}]);


  }


  useEffect(() => {
    if (results.data.length === 0)
    {
      return;
    }


    // Calculate Reliability Index
    // https://github.com/CoSci-LLC/SlideforNETWeb/issues/22
    // (mean-1)/(standard deviation)
    function calculate_reliability_index(arr: number[]) {
      const mean = arr.reduce((a, b) => a + b, 0) / results.data.length;

      // Assigning (value - mean) ^ 2 to every array item
      arr = arr.map((k)=>{
        return (k - mean) ** 2
      })
      
      // Calculating the sum of updated array
      let sum = arr.reduce((acc, curr)=> acc + curr, 0);
        
      // Calculating the variance
      //let variance = sum / arr.length
        
      // Returning the standard deviation
      const stddev = Math.sqrt(sum / arr.length);

      return (mean - 1) / stddev;
    }

    const r = results.data[0].r;
    const precision = 3;

    set_lateral_root_reinforcement( (r.rootLateral/1000).toFixed(precision) );
    
    set_basal_root_reinforcement( (r.basalRoot/1000).toFixed(precision) );


    set_tree_stand_density(inputs.speciesDensity.reduce( (a, b) => a+b, 0).toFixed(0) );

    const vegetation_weight = r.vegetationWeight / 1000 / r.area;
    set_tree_stand_volume( (vegetation_weight * Math.pow(10, 7) / 850.0).toFixed(precision) );
    set_vegetation_weight( vegetation_weight.toFixed(precision) );

    set_weight_in_soil_thickness( (r.vegetationWeight / r.area / r.densityWet * 100).toFixed(precision) );


    set_reliability_index_no_veg(calculate_reliability_index(results.data.map( (a) => a.r.FOSNoVeg )).toFixed(precision));
    set_reliability_index_veg(calculate_reliability_index(results.data.map( (a) => a.r.FOSVeg )).toFixed(precision));


    // Partial Security Factor Table
    const s = results.data[0].s;

    set_cohesion( (s.cohesion / 1000).toFixed(precision) );

    set_friction_angle( ( s.frictionAngle * 180 / Math.PI ).toFixed(precision) );

    set_psf_lateral_root_reinforcement( (s.rootLateral / 1000).toFixed(precision) );
    set_psf_lateral_root_reinforcement_char( (s.rootLateralChar / 1000).toFixed(precision) );

    set_psf_basal_root_reinforcement( (s.basalRoot / 1000).toFixed(precision) );
    set_psf_basal_root_reinforcement_char( (s.basalRootChar / 1000).toFixed(precision) );

    set_cohesion_char( (s.cohesionChar / 1000).toFixed(precision) );

    set_friction_angle_char( ( s.frictionAngleChar * 180 / Math.PI ).toFixed(precision) );

    update_fos_boxplot(results);

  }, [results.data, inputs.speciesDensity])


  return (
    <div>
      <Table id="vegetation_values" hover>
        <thead>
          <tr>
            <th>Tree Stand</th>
            <th>Value</th>
            <th>Unit</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Density</td>
            <td>{tree_stand_density}</td>
            <td>trees/ha</td>
          </tr>
          <tr>
            <td>Volume</td>
            <td>{tree_stand_volume}</td>
            <td>{getExponentLabel("m", "3", "/ha")}</td>
          </tr>
          <tr>
            <td>Weight of vegetation</td>
            <td>{vegetation_weight}</td>
            <td>{getExponentLabel("t/m","2")}</td>
          </tr>
          <tr>
            <td>Weight of vegetation in equivalent soil thickness</td>
            <td>{weight_in_soil_thickness}</td>
            <td>cm</td>
          </tr>
        </tbody>
        <thead>
          <tr>
            <th>Random landslides</th>
            <th/>
            <th/>
          </tr>
        </thead>
        <tbody>

          <tr>
            <td>Mean lateral root reinforcement</td>
            <td>{lateral_root_reinforcement}</td>
            <td>kN/m</td>
          </tr>
          <tr>
            <td>Mean basal root reinforcement</td>
            <td>{basal_root_reinforcement}</td>
            <td>kPa</td>
          </tr>
          
          <tr>
            <td>Reliability Index for vegetation</td>
            <td>{reliability_index_veg}</td>
            <td>-</td>
          </tr>
          <tr>
            <td>Reliability Index for no vegetation</td>
            <td>{reliability_index_no_veg}</td>
            <td>-</td>
          </tr>
        </tbody>
        </Table>
        <Table id="landslide_values" hover>
        <thead>
          <tr>
            <th>Deterministic landslides</th>
            <th>Characteristic Value</th>
            <th>Design Value</th>
            <th>Unit</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td>Cohesion</td>
            <td>{cohesion_char}</td>
            <td>{cohesion}</td>
            <td>kPa</td>
          </tr>
          <tr>
            <td>Friction angle</td>
            <td>{friction_angle_char}</td>
            <td>{friction_angle}</td>
            <td>deg</td>
          </tr>
          <tr>
            <td>Basal root reinforcement</td>
            <td>{psf_basal_root_reinforcement_char}</td>
            <td>{psf_basal_root_reinforcement}</td>
            <td>kPa</td>
          </tr>
          <tr>
            <td>Lateral root reinforcement</td>
            <td>{psf_lateral_root_reinforcement_char}</td>
            <td>{psf_lateral_root_reinforcement}</td>
            <td>kN/m</td>
          </tr>
        </tbody>
        </Table>

        <br/>
        <hr/>
        <br/>

        <GroupedBoxPlot data={data}
          labels={labels}
          legend={legend}
          yaxis={{invert: true}}
        />
    </div>
  );
}

export default TabulatedResultsView;
