// https://github.com/CoSci-LLC/SlideforNETWeb/issues/22
// If we have data that isn't available for these trees, let's not return anything useful.

/**
 * Checks if the array has only valid trees defined in it. This is a left over artifact from issue#23. This method should disappear once future changes are made.
 */
export function ContainsValidTrees(array: number[]) {
    
    //Only the following is allowed:
        // 1 KiLib::Trees::Species::SpeciesID::BetulaPendula
        // 3 KiLib::Trees::Species::SpeciesID::FagusSylvatica
        // 4 KiLib::Trees::Species::SpeciesID::PiceaAbies
        // 6 KiLib::Trees::Species::SpeciesID::PinusSylvestris
    // Anything else will be removed!

    if (array.length < 7) {
        throw new Error("Invalid array length!");
    }

    const not_allowed_indices = [0,2,5,7];

    // Check entries less than 7.
    for(let i of not_allowed_indices) {
        if (array[i] !== 0) {
            return false;
        }
    }

    // Check everything after 7.
    for(let i = 7; i < array.length; i++ ) {
        if (array[i] !== 0) {
            return false;
        }
    }

    // We have verified that only values 1,3,4,6 are approved.
    return true;
} 
