import React, {useEffect} from 'react';
import './BasalRootReinforcementView.css';
import ScatterChart from 'components/charts/ScatterChart/ScatterChart';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import {Point, ILineData} from 'components/charts/ScatterChart/components/Area';
 
const labels = { x: "Basal Root Reinforcement (kPa)", y: "Soil Thickness (m)" };

function BasalRootReinforcementView() {


  const results = useSelector((state: RootState) => state.WASMApplicationResult);
  const [data, setData] = React.useState<ILineData>({name: "", points: []});

  useEffect(() => {
    const rvalues = []
    const svalues = []

    for(let i = 0; i < results.size; i++) {
      rvalues.push(
        [
          results.data[i].r.basalRoot/1000,
          results.data[i].r.soilThickness
        ] as Point)
      svalues.push(
        [
          results.data[i].s.basalRoot/1000,
          results.data[i].s.soilThickness
        ] as Point)
    }

    const data = {
      name: "All Landslides",
      points: [
        rvalues,
        svalues
      ]
    }

    setData(data);

  }, [results]);

  return (
    <div>

      <ScatterChart
        data={data}
        title="Soil Thickness vs Basal Root"
        labels={labels}
      />
   </div>
  );
}

export default BasalRootReinforcementView;
