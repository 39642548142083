/**
 * We are using this to disable certain trees from showing in the interface
 * without changing the array position. Definitely not a good solution, but 
 * it works. There is an issue https://github.com/CoSci-LLC/SlideforNETWeb/issues/23
 * to try to fix that in the future. Requires changes to both SlideforNET and SlideforNETWeb
 */
 const specices_map = new Map([
    ["Tree of heaven (Ailanthus altissima)",  0],
    ["Grey Alder (Alnus incana)", -1],
    ["Birch (Betula pendula)",  1],
    ["Chestnut (Castanea sativa)",  2],
    ["Beech (Fagus sylvatica)",  3],
    ["Jatropha (Jatropha curcas L.)", -1],
    ["Spruce (Picea abies)",  4],
    ["Pinus radiata (Pinus radiata)",  5],
    ["Scots pine (Pinus sylvestris)",  6],
    ["Black poplar (Populus nigra)",  7],
    ["Pubescent oak (Quercus pubescens)",  8],
    ["Chinese windmill palm (Trachycarpus fortunei)" ,  9],
  ]);
  
  const index_species_map = new Map([
    [ 0, "Tree of heaven (Ailanthus altissima)"],
    [-1, "Invalid"],
    [ 1, "Birch (Betula pendula)"],
    [ 2, "Chestnut (Castanea sativa)"],
    [ 3, "Beech (Fagus sylvatica)"],
    [ 4, "Spruce (Picea abies)"],
    [ 5, "Pinus radiata (Pinus radiata)"],
    [ 6, "Scots pine (Pinus sylvestris)"],
    [ 7, "Black poplar (Populus nigra)"],
    [ 8, "Pubescent oak (Quercus pubescens)"],
    [ 9, "Chinese windmill palm (Trachycarpus fortunei)" ],
  ]);


export {specices_map, index_species_map};