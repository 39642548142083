import './UserManualView.css';

function UserManualView() {

  return (
    <div>
        <a href="/static/SlideforNET_User_Manual_v0.1.pdf">Click here to download the PDF</a>
        <object data="/static/SlideforNET_User_Manual_v0.1.pdf" type="application/pdf" style={{ width: "100%", height: "100vh"}}>
            <p>Alternative text - include a link <a href="http://africau.edu/images/default/sample.pdf">to the PDF!</a></p>
        </object>
    </div>
  );
}

export default UserManualView;
