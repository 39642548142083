import React, { useEffect } from 'react';
import './MainPage.css';
import { NavBar, Footer, Tabs } from 'components/layout';
import { InputTab, LandslideProbabilityTab, LandslideThicknessTab, BasalRootReinforcementTab, StandStateDiagramView, TabulatedResultsView, DownloadView, UserManualView} from 'views/Main/Tabs'
import { Container, Row, Col, Modal, Button, Spinner} from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'store';
import {  hideErrorMessage, setCurrentTab, showSoilThicknessModal, showSoilThicknessFractionModal} from 'store/ui/ui.slice';
import { SelectCallback  }from '@restart/ui/cjs/types';
import soil_thickness_fraction_image from 'assets/saturated_soil_thickness_fraction.png';
import soil_thickness_image from 'assets/soil_thickness.png';

function MainPage() {

  const errorMessageModalVisible = useSelector((state: RootState) => state.UI.modal_error_visible);
  const errorMessageContent = useSelector((state: RootState) => state.UI.modal_error_message);
  const processingCompleted = useSelector((state: RootState) => state.UI.processing_completed);

  const soilThicknessFractionModalVisible = useSelector((state: RootState) => state.UI.modal_soil_thickness_fraction);
  const soilThicknessModalVisible = useSelector((state: RootState) => state.UI.modal_soil_thickness);
  
  const stateDirty = useSelector((state: RootState) => state.WASMApplication.dirty);
  const currentTabName = useSelector((state: RootState) => state.UI.current_tab_name);
  const dispatch = useDispatch()

  const tabs = [
    {
      name: "Input",
      component: InputTab,
      enabled: true
    },
    {
      name: "Landslide Probability",
      component: LandslideProbabilityTab,
      enabled: processingCompleted && !stateDirty
    },
    {
      name: "Tabulated Results",
      component: TabulatedResultsView,
      enabled: processingCompleted && !stateDirty
    },
    {
      name: "Basal Root Reinforcement",
      component: BasalRootReinforcementTab,
      enabled: processingCompleted && !stateDirty
    },
    {
      name: "Landslide Thickness",
      component: LandslideThicknessTab,
      enabled: processingCompleted && !stateDirty
    },
    {
      name: "Stand State Diagram",
      component: StandStateDiagramView,
      enabled: processingCompleted && !stateDirty
    },
    {
      name: "Download",
      component: DownloadView,
      enabled: processingCompleted && !stateDirty
    },
    {
      name: "User Manual",
      component: UserManualView,
      enabled: true
    }
  ]

  useEffect(() => {
    if (processingCompleted)
      dispatch(setCurrentTab("Landslide Probability"));
    window.scroll({top: 0, left: 0, behavior: 'smooth'});
  }, [processingCompleted, dispatch])

  const onSelectCallback : SelectCallback = (k, e) => {
    if (k !== null) {
      dispatch(setCurrentTab(k));
    } 
  }

  return (
    <div>
      <Modal centered show={errorMessageModalVisible}  backdrop='static'>
        <Modal.Body style={{ textAlign: 'center', border: '.5em solid red'}}>
          <div>
            <p>An error occured. Please reload the page. If it happens again, please report it.</p>
            <p>{errorMessageContent}</p>
            <Button variant="secondary" onClick={(event: React.MouseEvent) => dispatch(hideErrorMessage())}>
            Close
          </Button>
          </div>
        </Modal.Body>
      </Modal>
      <Modal show={false} backdrop='static'>
        <Modal.Body>
          <div style={{ textAlign: 'center'}}>
            <p>Calculating... Please Wait...</p>
            <Spinner animation='border'/>
          </div>
        </Modal.Body>
      </Modal>



      <Modal centered show={soilThicknessFractionModalVisible}  backdrop='static'>
        <Modal.Body style={{ textAlign: 'center', border: '.2em solid blue'}}>
          <div >
            <img style={{width: "100%"}} src={soil_thickness_fraction_image} alt="Soil Thickness Fraction Explanation Image"/>
            <Button variant="secondary" onClick={(event: React.MouseEvent) => dispatch(showSoilThicknessFractionModal(false))}>
            Close
          </Button>
          </div>
        </Modal.Body>
      </Modal>

      <Modal centered show={soilThicknessModalVisible}  backdrop='static'>
        <Modal.Body style={{ textAlign: 'center', border: '.2em solid blue'}}>
          <div>
          <img style={{width: "100%"}} src={soil_thickness_image} alt="Soil Thickness Explanation Image"/>
            <Button variant="secondary" onClick={(event: React.MouseEvent) => dispatch(showSoilThicknessModal(false))}>
            Close
          </Button>
          </div>
        </Modal.Body>
      </Modal>



      <div className="App">
        <NavBar/>
          <Container fluid="md" >
            <Row>
              <Col>
                <Tabs tabs={tabs} defaultActiveKey={tabs[0].name} activeKey={currentTabName} onSelect={onSelectCallback}/>
              </Col>
            </Row>  
          </Container>
        </div>
      <Footer/>
    </div>
  );
}

export default MainPage;
