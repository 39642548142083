import React from "react";
import Accordion from 'react-bootstrap/Accordion';
import Card from "react-bootstrap/Card";
import { NumberField } from "components/forms";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Button from 'react-bootstrap/Button';
import Tooltip from 'react-bootstrap/Tooltip';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'store';
import { setWASMApplicationState } from 'store/wasmApplication/wasmApplication.slice';
import { showSoilThicknessModal, showSoilThicknessFractionModal } from 'store/ui/ui.slice';
import './SlopeCharacteristics.css';
import { getExponentLabel } from 'utils/labels';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import AccordionToggle from "components/forms/AccordionToggle/AccordionToggle";


export interface IComponentState {
  slope: number,
  saturatedThicknessFraction: number,
  soilthickness_mean: number,
  soilthickness_sd: number,
  passiveEarthPressureCorrection: number,
  elevation: number,
  aspectDeg: number,
  t0: number
}

export default function SlopeCharacteristics() {

  const dispatch = useDispatch()
  const WASMApplicationState = useSelector((state: RootState) => state.WASMApplication);

  const initial_state: IComponentState = {
    slope: WASMApplicationState.slope,
    saturatedThicknessFraction: WASMApplicationState.saturatedThicknessFraction,
    soilthickness_mean: WASMApplicationState.soilthickness_mean,
    soilthickness_sd: WASMApplicationState.soilthickness_sd,
    passiveEarthPressureCorrection: WASMApplicationState.passiveEarthPressureCorrection,
    elevation: WASMApplicationState.elevation,
    aspectDeg: WASMApplicationState.aspectDeg,
    t0: WASMApplicationState.t0
  }

  const [parameters, setParameters] = React.useState<IComponentState>(initial_state);

  /**  
     * Update the local state while the component is changing.
     * @param event HTML Input Event
     */
  function handleTextBoxOnChange(event: React.ChangeEvent<HTMLInputElement>) {
    setParameters({
      ...parameters,
      [event.target.name]:
        event.target.value === ""
          ? event.target.value
          : parseFloat(event.target.value)
    });
  }

  /**
   * Update global state once the control is unfocused
   * @param event onBlur Event
   */
  function handleNumberFieldOnBlur(event: React.FocusEvent<HTMLInputElement>) {
    dispatch(setWASMApplicationState({
      ...WASMApplicationState,
      ...parameters
    }));
  }


  const form_name = "Slope Characteristics";

  return (
    <Card>
      <Card.Header>
        <AccordionToggle eventKey={form_name}><span className="slope-char-header">➥</span>{form_name}{" "}</AccordionToggle>
      </Card.Header>
      <Accordion.Collapse eventKey={form_name}>
        <Container>
          <Row>
            <Col md={6} sm={12}>
              <NumberField
                label="Mean Gradient of Slope"
                unit="deg"
                name="slope"
                value={parameters.slope}
                onChange={handleTextBoxOnChange}
                onBlur={handleNumberFieldOnBlur}
              />
            </Col>
            <Col md={6} sm={12}>
              <NumberField
                label={<div>Saturated Soil Thickness Fraction<Button variant="light" className="d-inline-flex align-items-center minimal" onClick={() => dispatch(showSoilThicknessFractionModal(true))}  >
                  <FontAwesomeIcon icon={faInfoCircle} />
                </Button></div>}
                unit="-"
                step="0.01"
                min={0}
                max={1}
                name="saturatedThicknessFraction"
                value={parameters.saturatedThicknessFraction}
                onChange={handleTextBoxOnChange}
                onBlur={handleNumberFieldOnBlur}
              />
            </Col>
          </Row>
          <Row>
            <Col md={6} sm={12}>
              <NumberField
                label="Aspect (Slope orientation)"
                unit="deg"
                name="aspectDeg"
                value={parameters.aspectDeg}
                onChange={handleTextBoxOnChange}
                onBlur={handleNumberFieldOnBlur}
              />
            </Col>
            <Col md={6} sm={12}>
              <NumberField
                label="Elevation"
                unit="m"
                step="1"
                min={0}
                name="elevation"
                value={parameters.elevation}
                onChange={handleTextBoxOnChange}
                onBlur={handleNumberFieldOnBlur}
              />
            </Col>
          </Row>
          <Row>
            <Col md={6} sm={12}>
              <NumberField
                label="Time needed for tree to reach 1.3 m height"
                unit="year"
                name="t0"
                value={parameters.t0}
                onChange={handleTextBoxOnChange}
                onBlur={handleNumberFieldOnBlur}
              />
            </Col>
          </Row>
          <hr />
          <Row>
            <Col md={6} sm={12} className="single-col-div">
              <Row>
                <p className="align-items-center minimal">Soil Thickness</p>
                <Button variant="light" className="d-inline-flex align-items-center minimal" onClick={() => dispatch(showSoilThicknessModal(true))} >
                  <FontAwesomeIcon icon={faInfoCircle} />
                </Button>
              </Row>
            </Col>
            <Col md={3} sm={12}>

              <NumberField
                label="Mean"
                unit="m"
                name="soilthickness_mean"
                min={0}
                step="0.01"
                value={parameters.soilthickness_mean}
                onChange={handleTextBoxOnChange}
                onBlur={handleNumberFieldOnBlur}
              />
            </Col>
            <Col md={3} sm={12}>
              <NumberField
                label="std. dev."
                unit="m"
                min={0}
                name="soilthickness_sd"
                step="0.01"
                value={parameters.soilthickness_sd}
                onChange={handleTextBoxOnChange}
                onBlur={handleNumberFieldOnBlur}
              />
            </Col>
          </Row>
        </Container>
      </Accordion.Collapse>
    </Card>
  );
}


/*
<Row>
          <Col md={6} sm={12}>
            <NumberField
              label="Saturated Thickness Fraction"
              unit=""
              name="saturatedThicknessFraction"
              value={parameters.saturatedThicknessFraction}
              onChange={handleTextBoxOnChange}
            />
          </Col>
          <Col md={6} sm={12}>
            <NumberField
              label="Rainfall Rate"
              unit="mm/hr"
              name="rainfallRate"
              value={parameters.rainfallRate}
              onChange={handleTextBoxOnChange}
            />
          </Col>
        </Row>*/
