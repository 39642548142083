import * as d3 from "d3";
import { useEffect, useRef } from "react";
import NibiousChartLine, { ILineData } from './components/Area';
import Legend from '../GroupedBarChart/components/Legend';
import {colors} from 'components/charts/Colors';

interface Props {
  data: ILineData,
  title: string,
  labels: {
    x: string,
    y: string
  }
}

const original_width = 1000;
const original_height = 500;
const margin = { top: 50, right: 30, bottom: 10, left: 50 };
const width = original_width - margin.left - margin.right;
const height = original_height - margin.top - margin.bottom;

export default function LineChart(props: Props) {

  const axisTopRef = useRef<SVGGElement>(null);
  const axisLeftRef = useRef<SVGGElement>(null);
  useEffect(() => {

    

    const max_x = props.data.points.flat().reduce((a, b) => Math.max(a, b[0]), -Infinity);
    const max_y = props.data.points.flat().reduce((a, b) => Math.max(a, b[1]), -Infinity);


    const scaleX = d3.scaleLinear().domain([0, max_x]).range([0, width]);
    const scaleY = d3
      .scaleLinear()
      .domain([0, max_y])
      .range([0, height]);

    if (axisTopRef.current) {
      d3.select(axisTopRef.current).selectAll("*").remove();
      d3.select(axisTopRef.current).call(d3.axisTop(scaleX));
      d3.select(axisTopRef.current).append("text")
        .attr("x", width/2)
        .attr("y", -30)
        .attr('text-anchor', 'middle')
        .attr('font-weight', 'bold')
        .attr('font-size', '1.5em')
        .attr("fill", "currentColor")
        .text(props.labels.x);
    }

    if (axisLeftRef.current) {
      d3.select(axisLeftRef.current).selectAll("*").remove();
      d3.select(axisLeftRef.current).call(d3.axisLeft(scaleY));
      d3.select(axisLeftRef.current).append("text")
        .attr("x", -height/2)
        .attr("y", -margin.left + 15)
        .attr('text-anchor', 'middle')
        .attr('font-weight', 'bold')
        .attr('font-size', '1.5em')
        .attr("transform", "rotate(-90)")
        .attr("fill", "currentColor")
        .text(props.labels.y);
    }
  }, [props.labels, props.data.points]);

  const legend_items = [
    {
      name: "Random mean values",
      color: colors[0]
    },
    {
      name: "Deterministic values",
      color: colors[1]
    }
  ];


  return (
    <>
      <svg
        style={{
            height: "100%",
            marginRight: "0px",
            marginLeft: "0px",
            marginBottom: `${margin.bottom}px`
        }}
        viewBox={`0 0 ${original_width} ${original_height}`}
        id="basal_root_reinforcement"
      >
        <g transform={`translate(${margin.left}, ${margin.top})`}>
          <g ref={axisTopRef} transform={`translate(0, 0)`} />
          <g ref={axisLeftRef} />
          <NibiousChartLine data={props.data} width={width} height={height}/>
          <Legend items={legend_items} x={width - 200} y={100}/>
        </g>
      </svg>
    </>
  );
}
