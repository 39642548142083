import * as d3 from "d3";
import { useEffect, useRef, ReactElement, useState } from "react";
import Box from './components/Box';
import Legend, { ILegendItem } from '../GroupedBarChart/components/Legend';
import {colors} from 'components/charts/Colors';

export interface IData {
  q1: number, 
  q3: number, 
  max: number, 
  min: number, 
  median: number,
  number: number
}

export interface IGroupedData {
    label: string;
    values: IData[];
  }

interface Props {
  data: IGroupedData[];
  labels: {
    x: string,
    y: string
  }
  legend: {
    names: string[];
  }
  yaxis?: {
    invert?: boolean
  }
}

const original_width = 1000;
const original_height = 500;

const margin = { top: 10, right: 0, bottom: 40, left: 50 };
const width = original_width - margin.left - margin.right;
const height = original_height - margin.top - margin.bottom;

export default function GroupedBoxChart(props: Props) {

  const axisBottomRef = useRef<SVGGElement>(null);
  const axisLeftRef = useRef<SVGGElement>(null);

  const [boxes, setBoxes] = useState<ReactElement[]>([]);
  const [legend_items, setLegendItems] = useState<ILegendItem[]>([]);

  useEffect(() => {

    const labels = props.data.map(({ label }) => label);
    const sublabels = props.data.length === 0 ? [] : Object.keys(props.data[0].values);
    const values = props.data.map(({ values }) => values).flat();
  
    const scaleX = d3.scaleBand().domain(labels).range([0, width]).padding(0.2);
  
    const maxValues = values.reduce((a,b) => Math.max(a,b.max), -Infinity);
  
    let y_axis_invert = false;
    if (props.yaxis !== undefined) {
      if (props.yaxis.invert !== undefined) {
        y_axis_invert = props.yaxis.invert ;
      }
    }



    const scaleY = d3
      .scaleLinear()
      .domain([0, maxValues])
      .range(y_axis_invert ? [height, 0] : [0, height]);

    const subscaleX = d3
      .scaleBand()
      .domain(sublabels)
      .range([0, scaleX.bandwidth()])
      .padding(0.05);

    if (axisBottomRef.current) {
      d3.select(axisBottomRef.current).selectAll("*").remove();
      d3.select(axisBottomRef.current).call(d3.axisBottom(scaleX));
      d3.select(axisBottomRef.current).append("text")
      .attr("x", width/2)
      .attr("y", margin.bottom)
      .attr('text-anchor', 'middle')
      .attr('font-weight', 'bold')
      .attr('font-size', '1.5em')
      .attr("fill", "currentColor")
      .text(props.labels.x);
    }

    if (axisLeftRef.current) {
      d3.select(axisLeftRef.current).selectAll("*").remove();
      d3.select(axisLeftRef.current).call(d3.axisLeft(scaleY));
      d3.select(axisLeftRef.current).append("text")
        .attr("x", -height/2)
        .attr("y", -margin.left + 15)
        .attr('text-anchor', 'middle')
        .attr('font-weight', 'bold')
        .attr('font-size', '1.5em')
        .attr("transform", "rotate(-90)")
        .attr("fill", "currentColor")
        .text(props.labels.y);
    }
    
    const boxes = props.data.map(({label, values}, groupIndex) => (
      <g
        key={`rect-group-${groupIndex}`}
        transform={`translate(${scaleX(label)}, 0)`}
      >
      {values.map((value, barIndex) => (
        <g key={`${groupIndex}-${label}-${barIndex}`}>
          <Box
            key={`${label}-${groupIndex}-${barIndex}`}
            median={y_axis_invert ? height - scaleY(value.median): scaleY(value.median)}
            min={y_axis_invert ? height - scaleY(value.min): scaleY(value.min)}
            max={y_axis_invert ? height - scaleY(value.max): scaleY(value.max)}
            x={subscaleX(String(barIndex)) || 0}
            y={y_axis_invert ? height - scaleY(value.q1): scaleY(value.q1)}
            width={subscaleX.bandwidth()}
            height={y_axis_invert ? height - scaleY(value.q3 - value.q1): scaleY(value.q3 - value.q1)}
            color={colors[barIndex]}
          />
          <text fontSize=".8em" textAnchor="middle" x={(subscaleX(String(barIndex)) || 0) + subscaleX.bandwidth()/2} y={height + margin.bottom}>n: {value.number}</text>
          </g>
      ))}
      </g>
    ))
    setBoxes(boxes);

    const legend_items = props.legend.names.map((name, index) => {
      return {
        name: name,
        color: colors[index],
        clickable: false
      }
    })
    setLegendItems(legend_items);

  }, [ props.labels, props.data, props.legend ]);  

  return (
      <svg
        style={{
          height: "100%",
          marginRight: "0px",
          marginLeft: "0px",
          marginBottom: `${margin.bottom}px`
        }}
        viewBox={`0 0 ${original_width} ${original_height}`}
        id="landslide_thickness"
      >
        <g transform={`translate(${margin.left}, ${margin.top})`}>
          <g key="axisBottom" ref={axisBottomRef} transform={`translate(0, ${height})`} />
          <g key="axisLeft" ref={axisLeftRef} />
          {boxes}
          <Legend key="grouped_box_plot_key" items={legend_items} x={width - 200} y={10}/>
        </g>
      </svg>
  );
}



/*

{data.map(({ label, values }, groupIndex) => (
            <g
              key={`rect-group-${groupIndex}`}
              transform={`translate(${scaleX(label)}, 0)`}
            >
              {values.map((value, barIndex) => (
                <Box
                  key={`rect-${barIndex}`}
                  x={subscaleX(String(barIndex)) || 0}
                  y={scaleY(value)}
                  width={subscaleX.bandwidth()}
                  height={height - scaleY(value)}
                  color={colors[barIndex]}
                />
              ))}
            </g>
          ))}
*/