import React, {useEffect} from 'react';
import './StandStateDiagramView.css';
import ScatterChart from 'components/charts/MultipleScatterChart/ScatterChart';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import {Point, ILineData} from 'components/charts/MultipleScatterChart/components/Area';
import { GetCanopyCover } from 'services/RunApplication';

const labels = {x: "DBH (cm)", y:"Stand Density (Tress/ha)"};

function StandStateDiagramView() {

  
  const [data, setData] = React.useState<ILineData[]>([]);
  const [avgPoint, setAvgPoint] = React.useState<Point>([-1,-1]);
  const WASMApplicationState = useSelector((state: RootState) => state.WASMApplication);


  useEffect(() => {
    function calculate_avg_point() {
      // Calculate the average point
      // - the y value (tree density) is the sum of the trees/ha of all species
      //- the x value (dbh) is a weighted sum: dbh = (DBH1 * SD1 + DBH2 * SD2 ... + DBH4*SD4)/ (SD1 + SD2 + SD3 + SD4) where SD are tree density values. Let me know if this is not clear 
    
      const avg_y = WASMApplicationState.speciesDensity.reduce((partialSum, a) => partialSum + a, 0);

      if (avg_y === 0) {
        setAvgPoint([-1, -1]);
        return;
      }
  
      let avg_x = 0.0;
  
      for(let i = 0; i < WASMApplicationState.speciesDensity.length; i++) {
        avg_x += WASMApplicationState.speciesDensity[i] * WASMApplicationState.treeDBH[i]
      }
  
      avg_x /= avg_y;
      
      setAvgPoint([avg_x, avg_y]);
    }

    GetCanopyCover(WASMApplicationState).then( (returnMsg) => {
      
      calculate_avg_point();

      if (returnMsg.data.data.length === 0) {
        setData([]);
        return;
      }
      

      if (returnMsg.data.data.length === 0) {
        return;
      }

      let processed_data: {[key: string]: Point[]} = {};
      for(let d of returnMsg.data.data) {

        if (processed_data[d.canopy_cover] === undefined) {

          processed_data[d.canopy_cover] = [];
        }
        if (d.dbh < 0.05) {
          continue;
        }
        processed_data[d.canopy_cover].push([d.dbh, d.mean_stand_density]);
      }

      const organized_data = []
      for(let k in processed_data) {
        const d : ILineData = { 
          name: k + "%",
          points: processed_data[k],
          color: 'gray' 
        }
        organized_data.push(d);
      }

      setData(organized_data);

      

    })
  }, [WASMApplicationState.treeDBH, WASMApplicationState.speciesDensity]);

  return (
    <div>

    <ScatterChart
      data={data}
      labels={labels}
      avgPoint={avgPoint}
    />
    </div>
  );
}

export default StandStateDiagramView;
