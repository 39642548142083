import JSZip from 'jszip';
import { useSelector } from 'react-redux';
import { saveAs } from 'file-saver';
import { RootState } from 'store';
import { Button } from 'react-bootstrap';
import { wasmApplicationState } from 'store/wasmApplication/wasmApplication.slice';



const download_name = "SlideforNET_Download";

function create_download(state: wasmApplicationState) {

    const zip = new JSZip();    
    const folder = zip.folder(download_name);  
    if (folder === null) {
      throw new Error("Folder is null! Cannot create download");
    }

    // Generate CSV Tables
    function convert_table_as_csv(table_id: string, separator = ',') {

      // Select rows from table_id
      var rows = document.querySelectorAll('table#' + table_id + ' tr');

      // Construct csv
      var csv = [];
      for (var i = 0; i < rows.length; i++) {
          var row = [], cols = rows[i].querySelectorAll('td, th');
          for (var j = 0; j < cols.length; j++) {
              // Clean innertext to remove multiple spaces and jumpline (break csv)
              //@ts-ignore
              var data = cols[j].innerText.replace(/(\r\n|\n|\r)/gm, '').replace(/(\s\s)/gm, ' ')
              // Escape double-quote with double-double-quote (see https://stackoverflow.com/questions/17808511/properly-escape-a-double-quote-in-csv)
              data = data.replace(/"/g, '""');
  
              // If number, don't escape it
              const val = parseFloat(data)
  
              if(!isNaN(val)) {
                row.push(data)
              }
              else {
                // Push escaped string
                row.push('"' + data + '"');
              }
              
          }
          csv.push(row.join(separator));
      }
      var csv_string = csv.join('\n');
      return csv_string
    }
  
    const tableNames = [ "vegetation_values", "landslide_values" ];
    let csvData = "";

    tableNames.forEach(table => {
      csvData += convert_table_as_csv(table);
      csvData += "\n";
    })

    // Add data to the zip file
    folder.file("tabulated_results.csv", csvData);

    const UNITS: Record<string, string> = {
      "nLandslides": '-',
      "slope": "deg",
      "saturatedThicknessFraction":  "-",
      "passiveEarthPressureCorrection": "percent",
      "invGammaShape": "-",
      "invGammaScale": "km^2",
      "invGammaS": "km^2",
      "maxLandslideArea": "-",
      "elevation": "m",
      "aspectDeg": "deg",
      "t0": "years",
      "soilType": "",
      "cohesion": "kPa",
      "densityDry": "kg/m^3",
      "frictionAngle": "deg",
      "vgWetAlpha": "1/Pa",
      "vgWetN": "-",
      "fieldCapacityPressure": "Pa",
      "soilthickness_mean": "m",
      "soilthickness_sd": 'm',
      "species": '',
      "speciesDensity": '',
      "treeDBH": '',
      "gammaRootReinforcement": "-",
      "characteristicValueCoefficient": "-"
    }


    function toStringParams(state: Record<string, any>, param: string) {
      if (typeof(state[param]) === 'object' && !Array.isArray(state[param])) {
        let output = [];
        for (let subparam in state[param]) {
          output.push([`${param}_${subparam}`, state[param][subparam], UNITS[param] ]);
        }
        return output;
      } else { 
        return [[param, state[param], UNITS[param]]];
      }
    }
  
    // Add inputs parameters to download data (https://github.com/CoSci-LLC/BankforNETWeb/issues/105)
    var input_params = "";
    for (let param in state)
    {
      if (param === "dirty")
        continue;

      for (let output of toStringParams(state, param)) {
        //@ts-ignore
        input_params += `${output[0]}\t=\t${output[1]} ${output[2]}\n`;
      }
      
    }

    folder.file("input.params", input_params);
  
    // Add inputs parameters to download data as csv (https://github.com/CoSci-LLC/BankforNETWeb/issues/133)
    input_params = "name, value, units\n";
    for (let param in state)
    {
      if (param === "dirty")
        continue;
     
      for (let output of toStringParams(state, param)) {
        //@ts-ignore
        input_params += `${output[0]},${output[1]},${output[2]}\n`;
      }
    }
  
    folder.file("input.csv", input_params);
  
    const charts = ["landslide_probability", "landslide_thickness", "basal_root_reinforcement", "stand_state_diagram"];
  
    function generateCharts(charts: string[])
    {
      if(charts.length === 0) {
        //We are done!
        zip.generateAsync({type:"blob"}).then(function(content) {
          // see FileSaver.js
          saveAs(content, `SlideforNET_Download.zip`);
        });
        return;
      }
      const chart = charts[0];
  
      var svgEl = document.querySelector('svg#' + chart);
      if (svgEl === null) {
        throw new Error(`Cannot find svg: ${chart}`);
      }
      svgEl.setAttribute("xmlns", "http://www.w3.org/2000/svg");
      var svgData = svgEl.outerHTML;
      var preface = '<?xml version="1.0" standalone="no"?>\r\n';
      var svgBlob = new Blob([preface, svgData], {type:"image/svg+xml;charset=utf-8"});

      if (folder === null) {
        throw new Error("Folder is null. Cannot add svg to zip download");
      }
      folder.file(`${chart}.svg`, svgBlob, {base64: false});


      svgEl.setAttribute("height", "500");
      svgEl.setAttribute("width", "1000");
      svgData = svgEl.outerHTML;
      svgBlob = new Blob([preface, svgData], {type:"image/svg+xml;charset=utf-8"});
      var svgUrl = URL.createObjectURL(svgBlob);

      const img = document.createElement('img')

      img.src = svgUrl;
      img.height = 500;
      img.width = 1000;

      img.onload = function () {
        const canvas = document.createElement('canvas')
        canvas.width = 1000;
        canvas.height = 500;
        const ctx = canvas.getContext('2d')
        if (ctx === null) {
          throw new Error("Canvas context is null. Cannot create image for download");
        }
        ctx.drawImage(img, 0, 0, 1000, 500)

        if (folder === null) {
          throw new Error("Folder is null. Cannot add png to zip download");
        }
        folder.file(`${chart}.png`, canvas.toDataURL(`image/png`).slice(22), {base64: true}); 
        generateCharts(charts.slice(1));
      }
    }
  
    generateCharts(charts);    
    
  }
  


function DownloadView() {

    const WASMApplicationState = useSelector((state: RootState) => state.WASMApplication);

    return (
      <div>
        <Button onClick={() => {create_download(WASMApplicationState)}}>Click here to download images and data</Button>
      </div>
    );
}
  
export default DownloadView;
  