import {useEffect, useRef} from "react";
import * as d3 from "d3";
import {colors} from 'components/charts/Colors';

export type Point = [number, number]

export interface ILineData {
    points: Point[][],
    name: string
}

export interface ILineProps {
    data: ILineData,
    height: number,
    width: number,
}

export default function Area({data, width, height}: ILineProps) {
    const ref = useRef<SVGGElement>(null);

    useEffect(() => {
        if (ref.current) {

            // Loop over data point series
            d3.select(ref.current).selectAll("*").remove();

            const max_x = data.points.flat().reduce((a, b) => Math.max(a, b[0]), 0);
            const max_y = data.points.flat().reduce((a, b) => Math.max(a, b[1]), 0);
            const scaleX = d3.scaleLinear().domain([0, max_x]).range([0, width]);
            const scaleY = d3
                .scaleLinear()
                .domain([0, max_y])
                .range([height, 0]);

            let series_counter = 0;

            for (let series of data.points) {    
                // reduce the amount of data we are showing
                
                // for each datapoint
                    // is it within some bounds of another point? Check inside of a a list which should allow me to reduce the amount of items? I can work on a more efficient data structure later
                const new_data : Point[] = [];
                const radius = 4;
                for(let point of series) {
                    let found_existing_point = false;
                    for(let new_point of new_data) {
                        // Is it within the radius of another point?
                        if(Math.abs(scaleX(point[0]) - scaleX(new_point[0])) < radius/3) {
                            if(Math.abs(scaleY(point[1]) - scaleY(new_point[1])) < radius/3) {
                                found_existing_point = true;
                            }
                        }
                    }
                    if(!found_existing_point)
                        new_data.push(point);
                }

                d3.select(ref.current).append('g')
                    .selectAll("dot")
                    .data(new_data)
                    .enter()
                    .append("circle")
                    .attr("cx", function (d) { return scaleX(d[0]); } )
                    .attr("cy", function (d) { return height - scaleY(d[1]); } )
                    .attr("r", radius)
                    .style("fill", colors[series_counter]);

                series_counter++;
            }
        }
    }, [data, width, height]);

    return <g ref={ref}/>;
}