import React from "react";
import Accordion from 'react-bootstrap/Accordion';
import Card from "react-bootstrap/Card";
import { NumberField } from "components/forms";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Button from 'react-bootstrap/Button';
import Tooltip from 'react-bootstrap/Tooltip';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'store';
import { setWASMApplicationState } from 'store/wasmApplication/wasmApplication.slice';
import { getExponentLabel } from 'utils/labels';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import AccordionToggle from "components/forms/AccordionToggle/AccordionToggle";


export interface IComponentState {
  invGammaShape: number,
  invGammaScale: number,
  invGammaS: number,
  maxLandslideArea: number,
}

export default function LandslideAreaDistributionForm() {

  const dispatch = useDispatch()
  const WASMApplicationState = useSelector((state: RootState) => state.WASMApplication);

  const initial_state: IComponentState = {
    invGammaShape: WASMApplicationState.invGammaShape,
    invGammaScale: WASMApplicationState.invGammaScale,
    invGammaS: WASMApplicationState.invGammaS,
    maxLandslideArea: WASMApplicationState.maxLandslideArea,
  }

  const [parameters, setParameters] = React.useState<IComponentState>(initial_state);

  /**  
     * Update the local state while the component is changing.
     * @param event HTML Input Event
     */
  function handleTextBoxOnChange(event: React.ChangeEvent<HTMLInputElement>) {
    setParameters({
      ...parameters,
      [event.target.name]:
        event.target.value === ""
          ? event.target.value
          : parseFloat(event.target.value)
    });
  }

  /**
   * Update global state once the control is unfocused
   * @param event onBlur Event
   */
  function handleNumberFieldOnBlur(event: React.FocusEvent<HTMLInputElement>) {
    dispatch(setWASMApplicationState({
      ...WASMApplicationState,
      ...parameters
    }));
  }


  const form_name = "Landslide Area Inverse Gamma Distribution";

  return (
    <Card>
      <Card.Header>
        <AccordionToggle eventKey={form_name}><span className="landslideArea-char-header">➥</span>{form_name}{" "}
          <OverlayTrigger
            transition={false} //Currently disable transitions to prevent findDom Errors. See https://github.com/react-bootstrap/react-bootstrap/issues/5519
            delay={{ show: 100, hide: 500 }}
            overlay={<Tooltip id={"landslide_area_inverse_tooltop"}>See User Manual Equation (12)</Tooltip>}>

            <Button
              tabIndex={-1}
              variant="light"
              className="d-inline-flex align-items-center minimal"
            >
              <FontAwesomeIcon icon={faInfoCircle} />
            </Button>
          </OverlayTrigger>

        </AccordionToggle>
      </Card.Header>
      <Accordion.Collapse eventKey={form_name}>
        <Container>
          <Row>
            <Col md={6} sm={12}>
              <NumberField
                label="Shape Factor"
                unit="-"
                step="0.01"
                name="invGammaShape"
                value={parameters.invGammaShape}
                onChange={handleTextBoxOnChange}
                onBlur={handleNumberFieldOnBlur}
              />
            </Col>
            <Col md={6} sm={12}>
              <NumberField
                label="Scale Factor"
                unit={getExponentLabel("km", "2")}
                name="invGammaScale"
                step="0.00000001"
                value={parameters.invGammaScale}
                onChange={handleTextBoxOnChange}
                onBlur={handleNumberFieldOnBlur}
              />

            </Col>
          </Row>
          <Row>
            <Col md={6} sm={12}>
              <NumberField
                label="S Param"
                unit={getExponentLabel("km", "2")}
                step="0.00000001"
                name="invGammaS"
                value={parameters.invGammaS}
                onChange={handleTextBoxOnChange}
                onBlur={handleNumberFieldOnBlur}
              />
            </Col>

            <Col md={6} sm={12}>
              <NumberField
                label="Max Landslide Area"
                unit={getExponentLabel("m", "2")}
                name="maxLandslideArea"
                value={parameters.maxLandslideArea}
                onChange={handleTextBoxOnChange}
                onBlur={handleNumberFieldOnBlur}
              />
            </Col>
          </Row>
        </Container>
      </Accordion.Collapse>
    </Card>
  );
}


/*
<Row>
          <Col md={6} sm={12}>
            <NumberField
              label="Saturated Thickness Fraction"
              unit=""
              name="saturatedThicknessFraction"
              value={parameters.saturatedThicknessFraction}
              onChange={handleTextBoxOnChange}
            />
          </Col>
          <Col md={6} sm={12}>
            <NumberField
              label="Rainfall Rate"
              unit="mm/hr"
              name="rainfallRate"
              value={parameters.rainfallRate}
              onChange={handleTextBoxOnChange}
            />
          </Col>
        </Row>*/
