import * as d3 from "d3";
import { ReactElement, useEffect, useRef, useState } from "react";
import Legend, { ILegendItem } from './components/Legend';
import {colors_probability as colors} from 'components/charts/Colors';


export interface IGroupedData {
    label: string;
    values: number[];
  } 

interface ILegend {
  name: string;
  is_visible: boolean;
}

interface Props {
  data: IGroupedData[];
  defaultMaxY?: number;
  labels: {
    x: string,
    y: string
  };
  legend: ILegend[];
}

interface BarProps {
  x: number;
  y: number;
  width: number;
  height: number;
  color: string;
  className: string;
}

function Bar({
  x,
  y,
  width,
  height,
  color,
  className
}: BarProps) {
  const radius = height === 0 ? 0 : width * 0.15;

  return (
    <path
      d={`
      m${x},${y + radius}
      a${radius},${radius} 0 0 1 ${radius},${-radius}
      h${width - 2 * radius}
      a${radius},${radius} 0 0 1 ${radius},${radius}
      v${height - radius}
      h-${width}
      z
    `}
      fill={color}
    />
  );
}


const original_width = 1000;
const original_height = 500;

const margin = { top: 10, right: 0, bottom: 50, left: 50 };
const width = original_width - margin.left - margin.right;
const height = original_height - margin.top - margin.bottom;

export default function GroupedBarChart(props: Props) {

  const axisBottomRef = useRef<SVGGElement>(null);
  const axisLeftRef = useRef<SVGGElement>(null);
  
  const [bars, setBars] = useState<ReactElement[]>([]);
  const [legend_items, setLegendItems] = useState<ILegendItem[]>([]);

  useEffect(() => {

    const labels = props.data.map(({ label }) => label);

    const sublabels = props.data.length === 0 ? [] : Object.keys(props.data[0].values).filter((item, index) => { return legend_items[index].is_visible});

    const values = props.data.map(({ values }) => values).flat();
  
    const maxY = values.reduce((a,b) => Math.max(a,b), -Infinity) === 0 ? props.defaultMaxY ?? 100 : values.reduce((a,b) => Math.max(a,b), -Infinity);
  
    const scaleX = d3.scaleBand().domain(labels).range([0, width]).padding(0.2);
    const scaleY = d3
      .scaleLinear()
      .domain([0, maxY])
      .range([height, 0]);

    const subscaleX = d3
      .scaleBand()
      .domain(sublabels)
      .range([0, scaleX.bandwidth()])
      .padding(0.05);

    if (axisBottomRef.current) {
      d3.select(axisBottomRef.current).selectAll("*").remove();
      d3.select(axisBottomRef.current).call(d3.axisBottom(scaleX)).selectAll("text")  
      .style("text-anchor", "end")
      .attr("dx", "-.8em")
      .attr("dy", ".15em")
      .attr("transform", "rotate(-65)");;
      d3.select(axisBottomRef.current).append("text")
        .attr("x", width/2)
        .attr("y", margin.bottom)
        .attr('text-anchor', 'middle')
        .attr('font-weight', 'bold')
        .attr('font-size', '1.5em')
        .attr("fill", "currentColor")
        .text(props.labels.x)
      
    }

    if (axisLeftRef.current) {
      d3.select(axisLeftRef.current).selectAll("*").remove();
      d3.select(axisLeftRef.current).call(d3.axisLeft(scaleY));
      d3.select(axisLeftRef.current).append("text")
        .attr("x", -height/2)
        .attr("y", -margin.left + 15)
        .attr('text-anchor', 'middle')
        .attr('font-weight', 'bold')
        .attr('font-size', '1.5em')
        .attr("transform", "rotate(-90)")
        .attr("fill", "currentColor")
        .text(props.labels.y);
      
    }


    const bars = props.data.map(({ label, values }, groupIndex) => {
      return (
        <g
          key={`rect-group-${groupIndex}`}
          transform={`translate(${scaleX(label)}, 0)`}
        >
          {values.map((value, barIndex) => {
            if (legend_items[barIndex].is_visible)
            return  <Bar
              className={colors[barIndex]}
              key={`rect-${barIndex}`}
              x={subscaleX(String(barIndex)) || 0}
              y={scaleY(value)}
              width={subscaleX.bandwidth()}
              height={height - scaleY(value)}
              color={colors[barIndex]}
            />
            return <g key={`empty-rect-${barIndex}`}/>
          })}
        </g>
      );
    });
    setBars(bars);
  }, [props.data, props.labels, props.defaultMaxY, props.legend, legend_items]);

  useEffect(() => {
    const legend_items = props.legend.map((legend, index) => {
      return {
        name: legend.name,
        color: colors[index],
        clickable: true,
        is_visible: legend.is_visible
      }
    })
    setLegendItems(legend_items);
  }, [props.legend])


  return (
    <>
      <svg
        style={{
            height: "100%",
            marginRight: "0px",
            marginLeft: "0px",
            marginBottom: `${margin.bottom}px`
        }}
        viewBox={`0 0 ${original_width} ${original_height}`}
        id="landslide_probability"
      >
        <g transform={`translate(${margin.left}, ${margin.top})`}>
          <g ref={axisBottomRef} transform={`translate(0, ${height})`} />
          <g ref={axisLeftRef} />
          { bars }
          <Legend items={legend_items} x={width - 300} y={100} updateItems={setLegendItems}/>
        </g>
      </svg>
    </>
  );
}
