import * as d3 from "d3";
import { useEffect, useRef } from "react";
import NibiousChartLine, { ILineData, Point } from './components/Area';
import { GetLateralRootReinforcement, GetAgeDBHMap } from 'services/RunApplication';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import {ILLRPoint, IAgeDBHMap} from 'services/ApplicationConfig';
import { NumberValue } from "d3";

interface Props {
  data: ILineData[],
  labels: {
    x: string,
    y: string
  }
  avgPoint: Point
}

const original_width = 1000;
const original_height = 500;
const margin = { top: 40, right: 100, bottom: 40, left: 50 };
const width = original_width - margin.left - margin.right;
const height = original_height - margin.top - margin.bottom;

const data_width = 56;
const data_height = 96;
const scale_height = -1 * height/data_height;
const scale_width = width/data_width;

function get_lowest_value_on_right_side(c: d3.ContourMultiPolygon) {
  let low_text_location = data_width;

  if (c["coordinates"].length === 0) {
    return null
  }

  for(let i = 0; i < c["coordinates"][0][0].length; i++) {
    const cur_x = c["coordinates"][0][0][i][0];
    if (cur_x <= low_text_location) {
      low_text_location = cur_x;
    }
  }

  return low_text_location;
}

export default function LineChart(props: Props) {

  const axisTopRef = useRef<SVGGElement>(null);
  const axisBottomRef = useRef<SVGGElement>(null);
  const axisLeftRef = useRef<SVGGElement>(null);
  const axisRight1Ref = useRef<SVGGElement>(null);
  const axisRight2Ref = useRef<SVGGElement>(null);
  const shapeRef = useRef<SVGGElement>(null);
  const textOverlayRef = useRef<SVGGElement>(null);
  const clipPathRef = useRef<SVGGElement>(null);
  
  const WASMApplicationState = useSelector((state: RootState) => state.WASMApplication);

  useEffect(() => {
    const y_min = 50;
    const y_max = 1000;

    const x_min = 5;
    const x_max = 60;

    

    const scaleX = d3.scaleLinear().domain([x_min, x_max]).range([0, width]);
    const scaleY = d3
      .scaleLinear()
      .domain([y_max, y_min])
      .range([0, height]);

    if (axisBottomRef.current) {

      d3.select(axisBottomRef.current).selectAll("*").remove();
      d3.select(axisBottomRef.current).call(d3.axisBottom(scaleX));
      d3.select(axisBottomRef.current).append("text")
        .attr("x", width/2)
        .attr("y", margin.bottom)
        .attr('text-anchor', 'middle')
        .attr('font-weight', 'bold')
        .attr('font-size', '1.5em')
        .attr("fill", "currentColor")
        .text(props.labels.x);          
    }

    
  
    GetAgeDBHMap(WASMApplicationState).then(responseMsg => {
      d3.select(axisTopRef.current).selectAll("*").remove();

      if (responseMsg.data.data.length === 0) {
        return;
      }      
      if (axisTopRef.current) {

        const d = responseMsg.data.data.filter((k: IAgeDBHMap) => k.dbh <= 60).map( (d : IAgeDBHMap) => d.mean_age);

        d3.select(axisTopRef.current).call(d3.axisTop(scaleX).tickFormat((s, i) => d[i].toFixed(0)));
        d3.select(axisTopRef.current).append("text")
        .attr("x", width/2)
        .attr("y", -25)
        .attr('text-anchor', 'middle')
        .attr('font-weight', 'bold')
        .attr('font-size', '1.5em')
        .attr("fill", "currentColor")
        .text("Age (Years)");
      
      }
    })
      
  

    if (axisLeftRef.current) {
      d3.select(axisLeftRef.current).selectAll("*").remove();
      d3.select(axisLeftRef.current).call(d3.axisLeft(scaleY));
      d3.select(axisLeftRef.current).append("text")
        .attr("x", -height/2)
        .attr("y", -margin.left + 15)
        .attr('text-anchor', 'middle')
        .attr('font-weight', 'bold')
        .attr('font-size', '1.5em')
        .attr("transform", "rotate(-90)")
        .attr("fill", "currentColor")
        .text(props.labels.y);
    }

    if (axisRight1Ref.current) {
      d3.select(axisRight1Ref.current).selectAll("*").remove();

      function right1_scaleY(x: number) {
        return Math.sqrt( (10000 / x) * (2 / ( 3 * Math.sqrt(3)))).toFixed(2); 
      }

      d3.select(axisRight1Ref.current).call(d3.axisRight(scaleY).tickFormat((s, i) => right1_scaleY(s as number)));
      d3.select(axisRight1Ref.current).append("text")
        .attr("x", height/2)
        .attr("y", -40)
        .attr('text-anchor', 'middle')
        .attr('font-weight', 'bold')
        .attr('font-size', '1.5em')
        .attr("transform", "rotate(90)")
        .attr("fill", "currentColor")
        .text("Mean horizonal distance between trees (m)");
    }

    if (axisRight2Ref.current) {

      function right2_scaleY(x: number) {
        return Math.sqrt( (10000 / (x * Math.cos(WASMApplicationState.slope * Math.PI / 180))) * (2 / ( 3 * Math.sqrt(3)))).toFixed(2); 
      }

      d3.select(axisRight2Ref.current).selectAll("*").remove();
      d3.select(axisRight2Ref.current).call(d3.axisRight(scaleY).tickFormat((s) => right2_scaleY(s as number)));
      d3.select(axisRight2Ref.current).append("text")
        .attr("x", height/2)
        .attr("y", -40)
        .attr('text-anchor', 'middle')
        .attr('font-weight', 'bold')
        .attr('font-size', '1.5em')
        .attr("transform", "rotate(90)")
        .attr("fill", "currentColor")
        .text("Mean distance between trees parallel to slope (m)");
    }


    if (shapeRef.current) {

      d3.select(textOverlayRef.current).selectAll("*").remove();

      GetLateralRootReinforcement(WASMApplicationState).then((responseMsg) => {

                                          //n: columns(100), m: rows(96)
        const contours = d3.contours().size([data_width, data_height]);
        const path = d3.geoPath();

        const lrr = responseMsg.data.data.map((x: ILLRPoint) => x.mean_rr);

        if (isNaN(lrr[0])) {
          return;
        }
        if (props.avgPoint[0] !== -1 && props.avgPoint[1] !== -1) {
          d3.select(textOverlayRef.current)
                .append("path")
                .attr("transform", `translate(${scaleX(props.avgPoint[0])},  ${scaleY(props.avgPoint[1])})`)
                .attr("d", d3.symbol(d3.symbolWye, 64*3))
                .style("fill", '#9f3ec1')
        } 

        const m = lrr.reduce((a: number, b: number) => Math.max(a,b), -Infinity);

        //const thresholds: number[] = [0, 0.10, 0.25, 0.5, 1].concat([...Array(9).keys()].map((x: number) => { return Number((x + 1)*m/10);}));
        const thresholds: number[] = [0.1, 0.3, 0.5, 1, 2, 5, 10, 15, 20, 30, 40, 50, 60, 70, 80, 90, 100];

        var colors = d3.scaleLinear()
          //@ts-ignore
          //.range(["#E5F2EE", "#69b3a2"])
          .range(["#F5F0BB", "#73A9AD"]) //https://colorhunt.co/palette/f5f0bbdbdfaab3c89073a9ad
          .domain([0, thresholds.length])

        //colors = ["#F5F0BB", ]

        d3.select(shapeRef.current).selectAll("*").remove();

        for (let i in thresholds) {
          const c = contours.contour(lrr, thresholds[i]);
          const p = path(c);

          d3.select(shapeRef.current).append("path")
              .attr("d", p)
              .attr("stroke", "#b2d9cd")
              .attr("stroke-width", ".1px")
              .attr("fill", colors(Number(i)));


          const lowest_value =  get_lowest_value_on_right_side(c)
          if (lowest_value === null) {
            return;
          }
          const low_text_location = lowest_value + x_min;

          const x_contour_text = scaleX((low_text_location)) + 3;
          const y_contour_text = scaleY(950);
          d3.select(textOverlayRef.current)
            .append("text")
              .attr("x", x_contour_text) // shift the text a bit more right
              .attr("y", y_contour_text)
              .attr("transform", `rotate(75, ${x_contour_text}, ${y_contour_text})`)
              .text(thresholds[i].toFixed(1) + " kN/m")
              .attr("text-anchor", "start")
              //.attr("dominant-baseline", "middle")
              .style("fill", 'black' )
              .style("font-size", 10)
        }
      })
    }

    if (clipPathRef.current) {
      d3.select(clipPathRef.current).append("clipPath")
        .attr("id", "clip")
        .append("rect")
        .attr("width", width)
        .attr("height", height);
    }
  }, [props.labels, props.data, props.avgPoint, WASMApplicationState]);

  return (
    <>
      <svg
        style={{
            height: "100%",
            marginRight: "0px",
            marginLeft: "0px",
            marginBottom: `${margin.bottom}px`
        }}
        viewBox={`0 0 ${original_width} ${original_height}`}
        id="stand_state_diagram"
      >
        <g transform={`translate(${margin.left}, ${margin.top})`}>
          <g transform={`scale(.86, 1)`}>
            <g ref={clipPathRef} />
            <g ref={axisTopRef}/>
            <g ref={axisBottomRef} transform={`translate(0, ${height})`} />
            <g ref={axisLeftRef} />

            


            <g ref={shapeRef} transform={`scale(${scale_width}, ${scale_height}) translate(0, ${height/scale_height})`}  />
            <g>
            {
              props.data.map(d => {
                return <NibiousChartLine key={d.name + "chartline"} data={d} width={width} height={height}/>
              })
            }
            </g>
            <g ref={textOverlayRef} />
          </g>


          <g ref={axisRight1Ref} transform={`translate(${width - (margin.right + 15)},0)`} />
          <g ref={axisRight2Ref} transform={`translate(${width - (margin.right - 80 + 15)},0)`} />

        </g>
      </svg>
    </>
  );
}
