import { NumberField } from "components/forms";
import AccordionToggle from "components/forms/AccordionToggle/AccordionToggle";
import React, { useEffect } from "react";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import Row from "react-bootstrap/Row";
import Accordion from 'react-bootstrap/Accordion';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { IModule } from 'services/ApplicationType';
import { GetSoil, GetSoils } from 'services/RunApplication';
import { RootState } from 'store';
import { setWASMApplicationState } from 'store/wasmApplication/wasmApplication.slice';
import SoilPropertyRow from "./SoilPropertyRow";
import { getExponentLabel } from 'utils/labels';


export interface IViewState {
  vgWetAlpha: number,
  vgWetN: number
}

export default function SoilForm() {



  const dispatch = useDispatch()
  const WASMApplicationState = useSelector((state: RootState) => state.WASMApplication);
  const processingCompleted = useSelector((state: RootState) => state.UI.processing_completed);

  const initial_state: IViewState = {
    vgWetAlpha: WASMApplicationState.vgWetAlpha,
    vgWetN: WASMApplicationState.vgWetN,
  }


  const [parameters, setParameters] = React.useState<IViewState>(initial_state);
  const [dropdownName, setDropdownName] = React.useState("Select Soils");
  const isMobile = useMediaQuery({ query: `(max-width: 770px)` });
  const [soils, setSoils] = React.useState<string[]>([]);

  useEffect(() => {
    GetSoils().then((msg) => {
      const results = msg.data;
      const soils: string[] = [];
      for (let i = 0; i < results.size(); i++) {
        const soil = results.get(i);
        if (soil !== "ruedlingen") {
          const [long_name, short_name] = soil.split("-|-");
          soils.push(`${long_name} (${short_name.toUpperCase().trim()})`);
        }
      }
      setSoils(soils);
      results.delete();

      if (!processingCompleted) {
        //@ts-ignore
        handleSoilsOnSelect(null, { target: { text: "Silty sand (SM)" } } as EventTarget);
      }
    });
  }, []);

  useEffect(() => {
    setParameters(
      {
        vgWetAlpha: WASMApplicationState.vgWetAlpha,
        vgWetN: WASMApplicationState.vgWetN
      }
    )

  }, [WASMApplicationState])


  /**  
     * Update the local state while the component is changing.
     * @param event HTML Input Event
     */
  function handleTextBoxOnChange(event: React.ChangeEvent<HTMLInputElement>) {
    setParameters({
      ...parameters,
      [event.target.name]:
        event.target.value === ""
          ? event.target.value
          : parseFloat(event.target.value)
    });
  }

  /**
   * Update global state once the control is unfocused
   * @param event onBlur Event
   */
  function handleNumberFieldOnBlur(event: React.FocusEvent<HTMLInputElement>) {
    console.debug("Updating global state", parameters)
    dispatch(setWASMApplicationState({
      ...WASMApplicationState,
      ...parameters
    }));
  }

  function handleSoilsOnSelect(eventKey: any, event: React.SyntheticEvent<unknown, Event>): any {

    function breakout_distributions(distribution: IModule.IValueDistribution, precision: number, scale: number) {
      return {
        mean: Number((distribution.getNormal().getMean() / scale).toFixed(precision)),
        stdDev: Number((distribution.getNormal().getStdDev() / scale).toFixed(precision)),
        gamma: 1
      }
    }

    //@ts-ignore
    const soil_name = `${event.target.text.split(" (")[1][0]}${event.target.text.split(" (")[1][1]}`
    GetSoil(soil_name).then((msg) => {
      const soil: IModule.ISoil = msg.data;
      dispatch(setWASMApplicationState({
        ...WASMApplicationState,
        frictionAngle: breakout_distributions(soil.getFrictionAngleDistribution(), 1, Math.PI / 180),
        densityDry: breakout_distributions(soil.getDensityDryDistribution(), 0, 1),
        cohesion: breakout_distributions(soil.getCohesionDistribution(), 2, 1000),
        vgWetAlpha: soil.GetVGWetAlpha1() * 1000,
        vgWetN: soil.GetVGWetN1(),
      }));
      msg.data.delete();
      //@ts-ignore
      setDropdownName((event.target as HTMLLinkElement).text);
    })
  }

  const form_name = "Soil Parameters";

  return (
    <Card>
      <Card.Header>
        <AccordionToggle eventKey={form_name}><span className="soil-char-header">➥</span>{form_name}{" "}</AccordionToggle>
      </Card.Header>
      <Accordion.Collapse eventKey={form_name}>
        <Container>
          <Row>
            <Col>
              <DropdownButton onSelect={handleSoilsOnSelect} title={dropdownName} disabled={soils.length === 0} id="dropdown-basic">
                {
                  soils.map((soil) => {
                    return <Dropdown.Item eventKey={soil} key={`dropdown-item-${soil}`}>{soil}</Dropdown.Item>
                  })
                }
              </DropdownButton>

            </Col>
          </Row>
          {/* Header Row */
            isMobile ? "" :
              <Row>
                <Col md={3} sm={12}>
                  {/* Blank for table looks */}
                </Col>
                <Col md={3} sm={12}>
                  <p>Mean</p>
                </Col>
                <Col md={3} sm={12}>
                  <p>std. dev</p>
                </Col>
                <Col md={3} sm={12}>
                  <p>Partial Safety Factor</p>
                </Col>
              </Row>
          }

          {/* Cohesion Row */}
          <SoilPropertyRow parameterName={"Cohesion [kPa]"} distributionName={"cohesion"} step={"0.01"} />

          {/* Friction Angle Row */}
          <SoilPropertyRow parameterName={"Friction Angle [deg]"} distributionName={"frictionAngle"} step={"0.1"} />

          {/* Dry Density Row */}
          <SoilPropertyRow parameterName={getExponentLabel("Dry Density [km/m", "3", "]")} distributionName={"densityDry"} step={"1"} />

          <Row>
            <Col md={12}>
              <NumberField
                label="van Genuchten alpha parameter related to the inverse of the air entry suction"
                unit={getExponentLabel("kPa", "-1")}
                name="vgWetAlpha"
                step="0.00001"
                value={parameters.vgWetAlpha}
                onChange={handleTextBoxOnChange}
                onBlur={handleNumberFieldOnBlur}
              />
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <NumberField
                label="van Genuchten n parameter related to the pore-size distribution"
                unit="-"
                step="0.01"
                name="vgWetN"
                value={parameters.vgWetN}
                onChange={handleTextBoxOnChange}
                onBlur={handleNumberFieldOnBlur}
              />
            </Col>
          </Row>
        </Container>
      </Accordion.Collapse>
    </Card>
  );
}


/*
<Row>
          <Col md={6} sm={12}>
            <NumberField
              label="Saturated Thickness Fraction"
              unit=""
              name="saturatedThicknessFraction"
              value={parameters.saturatedThicknessFraction}
              onChange={handleTextBoxOnChange}
            />
          </Col>
          <Col md={6} sm={12}>
            <NumberField
              label="Rainfall Rate"
              unit="mm/hr"
              name="rainfallRate"
              value={parameters.rainfallRate}
              onChange={handleTextBoxOnChange}
            />
          </Col>
        </Row>*/
