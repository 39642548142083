import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store';


export interface landslideSideResult {
  area: number,
  FOSVeg: number,
  FOSNoVeg : number,
  basalRoot: number,
  basalRootChar: number,
  soilThickness: number,
  rootLateral: number,
  rootLateralChar: number,
  vegetationWeight: number,
  densityWet: number,
  cohesion: number,
  frictionAngle: number
}

export interface landslideResult {
  ID: number,
  r: landslideSideResult,
  s: landslideSideResult,
}

export interface vector {
  data: any[];
  size: number;
}

const initialState: vector = {
  data: [],
  size: 0
};

export const wasmApplicationResultSlice = createSlice({
  name: 'WASMApplicationResult',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setWASMApplicationResults: (state, action: PayloadAction<vector>) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      return action.payload;
    },

    // When the calculation is running, update state properly
    clearWASMApplicationResults: (state) => {
      state = initialState;
    },
  }
});

export const { setWASMApplicationResults, clearWASMApplicationResults } = wasmApplicationResultSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const getWASMApplicationState = (state: RootState) => state.WASMApplicationResult;

export default wasmApplicationResultSlice.reducer;

export { initialState };