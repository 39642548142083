import {useEffect, useRef} from "react";
import * as d3 from "d3";

export type Point = [number, number]

export interface ILineData {
    points: Point[],
    name: string,
    color: string
}

export interface ILineProps {
    data: ILineData,
    height: number,
    width: number
}


export default function Area({data, width, height}: ILineProps) {
    const ref = useRef<SVGGElement>(null);

    useEffect(() => {
        if (ref.current) {

            const min_x = 0.05;
            const max_x = data.points.reduce((a, b) => Math.max(a, b[0]), -Infinity);
            const max_y = 1000;
            const min_y = 50;
        
        
            const scaleX = d3.scaleLinear().domain([min_x, max_x]).range([0, width]);
            const scaleY = d3
                .scaleLinear()
                .domain([min_y, max_y])
                .range([height, 0]);
            
            d3.select(ref.current).selectAll("*").remove();

            // reduce the amount of data we are showing
            
            // for each datapoint
                // is it within some bounds of another point? Check inside of a a list which should allow me to reduce the amount of items? I can work on a more efficient data structure later

            const new_data : Point[] = [];
            const radius = 2;
            for(let point of data.points) {
                let found_existing_point = false;
                for(let new_point of new_data) {
                    
                    // Is it within the radius of another point?
                    if(Math.abs(scaleX(point[0]) - scaleX(new_point[0])) < radius/3) {
                        if(Math.abs(scaleY(point[1]) - scaleY(new_point[1])) < radius/3) {
                            found_existing_point = true;
                        }
                    }
                }
                if(!found_existing_point)
                    new_data.push(point);
            }

            d3.select(ref.current)
                .selectAll("dot")
                .data(new_data)
                .enter()
                .append("circle")
                .attr("cx", function (d) { return scaleX(d[0]); } )
                .attr("cy", function (d) { return scaleY(d[1]); } )
                .attr("clip-path", "url(#clip)")
                .attr("r", radius)
                .style("fill", data.color)

            var line = d3.line()
                .x(function (d) { return scaleX(d[0])})
                .y(function (d) { return scaleY(d[1])});
        
            var group = [{
                name: data.name,
                values: new_data
            }]

            d3.select(ref.current)
                .selectAll("connected_dots")
                .data(group)
                .enter()
                .append("path")
                .attr("clip-path", "url(#clip)")
                .attr("d", function(d) { return line(d.values)})
                .attr("stroke-width", radius)
                .style("stroke", data.color)
                .style("fill", "none")

            d3.select(ref.current).selectAll("myLabels")
            .data(group)
            .enter()
              .append('g')
              .append("text")
                .datum(function(d) { return {name: d.name, value: d.values[15]}; }) // keep only the last value of each time series
                .attr("transform", function(d) { return "translate(" + scaleX(d.value[0]) + "," + scaleY(d.value[1]) + ")"; }) // Put the text at the position of the last point
                .attr("x", 4) // shift the text a bit more right
                .text(function(d) { return d.name; })
                .style("fill", function(d){ return 'black' })
                .style("font-size", 12)
            }
    }, [data, width, height]);

    return <g ref={ref}/>;
}