import React, { ReactElement } from 'react';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Button from 'react-bootstrap/Button';
import Tooltip from 'react-bootstrap/Tooltip';
import { FontAwesomeIcon }from '@fortawesome/react-fontawesome';
import { faInfoCircle} from '@fortawesome/free-solid-svg-icons';

interface NumberFieldProps {
	controlId?: string | undefined | null,
	label?: string | JSX.Element | null,
	unit?: string | JSX.Element | null,
	disabled?: boolean,
	onChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
	onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void,
	value: number | null,
	name: string,
	step?: string | null | undefined,
	isInvalid?: boolean,
	feedback?: string | null | undefined,
	required?: boolean,
	info?: string,
	min?: number,
	max?: number,
	extra?: ReactElement | HTMLElement | undefined | null
}


export default function NumberField(props: NumberFieldProps) {
	const label = props.label;
	const unit = props.unit;
	const disabled = props.disabled
	const onChangeCallback = props.onChange;
	const onBlurCallback = props.onBlur !== undefined ? props.onBlur : () => {};
	const value = props.value;
	const name = props.name;
	const step = props.step || "any";
	const isInvalid = props.isInvalid;
	const feedback = props.feedback;
	const required = props.required === undefined ? true : props.required;
	const info = props.info;
	const extra = props.extra;


	var controlId;
	if(props.controlId === undefined) {
		if(typeof label === "string") {
			controlId = label.replace(/ +/g, "");
		}
		else if (label !== undefined && label !== null) {
			controlId = label.toString().replace(/ +/g, "");
		} else {
			throw Object.assign({message: "label or controlId attributes must be defined for component to work properly."});
		}
	} else {
		controlId = props.controlId;
	}

	function onFocusCallback(event: React.FocusEvent<HTMLInputElement>) {
		/*
		 * When a box is clicked (onFocus event), it should select all the
		 * values inside it. This will make it easier for students. This was
		 * requested on issue #127
		 */
		event.target.select();
	}

	function get_info_overlay() {	
			
		if(info === undefined) {
			return;
		}

		return (
			<OverlayTrigger
					transition={false} //Currently disable transitions to prevent findDom Errors. See https://github.com/react-bootstrap/react-bootstrap/issues/5519
					delay={{ show: 100, hide: 500}} 
					overlay={<Tooltip id={label+"tooltop"}>{info}</Tooltip>}>

						<Button
							tabIndex={-1}
							variant="light"
							className="d-inline-flex align-items-center"
						>
							<FontAwesomeIcon icon={faInfoCircle} />
						</Button>
			</OverlayTrigger> );

	}

		
	return(
		<Form.Group  style={{marginBottom: "1em"}} controlId={`controls.${controlId}`}>
			{label !== undefined ?
			<Form.Label>
				{label}

				{ get_info_overlay() }
			</Form.Label> : ""}
			<InputGroup>
				<Form.Control min={props.min} max={props.max}
					isInvalid={isInvalid}
					name={name}
					disabled={disabled || value === null} // If the value is null, the disable the component
					onChange={onChangeCallback} 
					value={value === null ? "" : value} 
					type="number" 
					step={step} 
					
					required={required}
					onFocus={onFocusCallback}
					onBlur={onBlurCallback}
					/>
				{unit !== undefined ? <InputGroup.Text>{unit}</InputGroup.Text> : ""  }							
				<Form.Control.Feedback type="invalid">{feedback}</Form.Control.Feedback>
			</InputGroup>
		</Form.Group>
	);
}