import React, { useContext } from 'react';
import { Col, Row, Container } from 'react-bootstrap';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import AccordionContext from 'react-bootstrap/AccordionContext';
import "./AccordionToggle.css"


export default function AccordionToggle({ children, eventKey }: { children: any, eventKey: any }) {
	const { activeEventKey } = useContext(AccordionContext);
	const decoratedOnClick = useAccordionButton(eventKey, () =>
		console.log('Changing accordion'),
	);

	const down = "🢃";
	const up = "🢁";

	const isCurrentEventKey = activeEventKey === eventKey;

	const arrow = isCurrentEventKey ? up : down;

	return (
		<button
			type="button"
			onClick={decoratedOnClick}
			className="accordion-button"
		>
			<Container>
				<Row style={{ "margin": "0px" }}>
					<Col style={{ "padding": "0px" }}>
						{children}
					</Col>
					<Col className="card-header-right">
						{arrow}
					</Col>
				</Row>
			</Container>
		</button>
	);
}
