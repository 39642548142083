import React from "react";
import { NumberField } from "components/forms";
import { useSelector, useDispatch } from 'react-redux';
import {  setWASMApplicationState } from 'store/wasmApplication/wasmApplication.slice';
import { RootState } from 'store';

export interface IComponentOutput {
    trees_per_km: number,
    dbh: number
}

export interface IComponentProps {
  speciesName: string,
  speciesIndex: number
}

export default function SpeciesInputRow(props: IComponentProps) {

    const dispatch = useDispatch()
    const WASMApplicationState = useSelector((state: RootState) => state.WASMApplication);

    const initial_state: IComponentOutput  = {  
      trees_per_km: WASMApplicationState.speciesDensity[props.speciesIndex],
      dbh: WASMApplicationState.treeDBH[props.speciesIndex] 
    }

    const [parameters, setParameters] = React.useState<IComponentOutput>(initial_state);

    /**
     * Update the local state while the component is changing.
     * @param event HTML Input Event
     */
    function handleTextBoxOnChange(event: React.ChangeEvent<HTMLInputElement>) {
      setParameters({
        ...parameters,
        [event.target.name]:
          event.target.value === ""
            ? event.target.value
            : parseFloat(event.target.value)
      });
    }

    /**
     * Update global state once the control is unfocused
     * @param event onBlur Event
     */
    function handleNumberFieldOnBlur() {
      const speciesDensity = [...WASMApplicationState.speciesDensity];
      speciesDensity[props.speciesIndex] = parameters.trees_per_km;

      let dbh = 0;
      if (parameters.trees_per_km !== 0) {
        dbh = parameters.dbh;
      }
      const treeDBH = [...WASMApplicationState.treeDBH];
      treeDBH[props.speciesIndex] =  dbh;

      dispatch(setWASMApplicationState({
        ...WASMApplicationState,
        speciesDensity: speciesDensity,
        treeDBH: treeDBH
      }));
    }

    return (
      <tr>
        <td>{props.speciesName}</td>
        <td>
          <NumberField
            controlId={props.speciesName + "trees_per_km_input"}
            min={0}
            name="trees_per_km"
            value={parameters.trees_per_km}
            onChange={handleTextBoxOnChange}
            onBlur={handleNumberFieldOnBlur}
            feedback="Must be postive values"
          />
        </td>
        <td>
          <NumberField
            controlId={props.speciesName + "dbh_input"}
            name="dbh"
            min={0}
            value={parameters.dbh}
            onChange={handleTextBoxOnChange}
            onBlur={handleNumberFieldOnBlur}
          />
        </td>
      </tr>
    );
}