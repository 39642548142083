/*
 * RunApplication.tsx
 *
 * Run the application. Define the configuration that must be used.
 */

import { ResultMessage, ResultStatus } from 'components/messages'
import { ApplicationConfig } from 'services/ApplicationConfig';
import ConfigFactory from 'services/ConfigFactory';
import { setWASMApplicationResults, vector} from 'store/wasmApplication/wasmApplicationResult.slice';
import { store } from 'store';
import { IModule } from 'services/ApplicationType';
import createVectorDouble from 'utils/createVectorDouble';
import { ContainsValidTrees} from 'utils/validateSelectedTrees';

export default async function RunApplication(config: ApplicationConfig): Promise<ResultMessage> {
     // @ts-ignore
    if(window.CreateWASMApplication === undefined) {
        return {
            'type': ResultStatus.Failure,
            "data": 'window.CreateWASMApplication === undefined',
            // @ts-ignore
            "message": "Scripts still loading... Please try again in a few moments. If this persists, refresh the page."
        }
    }

    // @ts-ignore
    return window.CreateWASMApplication().then((Module) => {
        // @ts-ignore
        if (window.WASMApplcationModule === undefined) {
            // @ts-ignore
            window.WASMApplcationModule = Module;
        }
		
		try {
			//Do something with the configuration
            console.debug("Module info: ", Module);
            console.debug("Incoming PWA Config: ", config);
            const configClass = ConfigFactory(Module, config);

            // Run application
            const output = Module.run(configClass, config.nLandslides);
            
            const results: vector = {
                data: [],
                size: output.size()
            };

            for(let i = 0; i < output.size(); i++) {
                results.data.push({
                    ID: output.get(i).ID,
                    r: {
                        area: output.get(i).r.area,
                        FOSVeg: output.get(i).r.FOSVeg,
                        FOSNoVeg: output.get(i).r.FOSNoVeg,
                        basalRoot: output.get(i).r.basalRoot,
                        basalRootChar: output.get(i).r.basalRootChar,
                        soilThickness: output.get(i).r.soilThickness,
                        rootLateral: output.get(i).r.rootLateral,
                        rootLateralChar: output.get(i).r.rootLateralChar,
                        vegetationWeight: output.get(i).r.vegetationWeight,
                        densityWet: output.get(i).r.densityWet,
                        cohesion: output.get(i).r.cohesion,
                        cohesionChar: output.get(i).r.cohesionChar,
                        frictionAngle: output.get(i).r.frictionAngle,
                        frictionAngleChar: output.get(i).r.frictionAngleChar
                    },
                    s: {
                        area: output.get(i).s.area,
                        FOSVeg: output.get(i).s.FOSVeg,
                        FOSNoVeg: output.get(i).s.FOSNoVeg,
                        basalRoot: output.get(i).s.basalRoot,
                        basalRootChar: output.get(i).s.basalRootChar,
                        soilThickness: output.get(i).s.soilThickness,
                        rootLateral: output.get(i).s.rootLateral,
                        rootLateralChar: output.get(i).s.rootLateralChar,
                        vegetationWeight: output.get(i).s.vegetationWeight,
                        densityWet: output.get(i).s.densityWet,
                        cohesion: output.get(i).s.cohesion,
                        cohesionChar: output.get(i).s.cohesionChar,
                        frictionAngle: output.get(i).s.frictionAngle,
                        frictionAngleChar: output.get(i).s.frictionAngleChar
                    }
                })
            }

            output.delete();
            configClass.delete();

            store.dispatch(setWASMApplicationResults(results));  

            return {
                'type': ResultStatus.Success,
                'message': '',
                'data': ''
            }
		} catch(err) {
            console.error("Encountered an error while running the application", err);
			return {
                'type': ResultStatus.Failure,
				"data": err,
                // @ts-ignore
				"message": Module.getExceptionMessage(err)
			}
		}
	});
}

export async function GetSoils(): Promise<ResultMessage> {
    // @ts-ignore
    if(window.CreateWASMApplication === undefined) {
        return {
            'type': ResultStatus.Failure,
            "data": 'window.CreateWASMApplication === undefined',
            // @ts-ignore
            "message": "Scripts still loading... Please try again in a few moments. If this persists, refresh the page."
        }
    }

    // @ts-ignore
    return window.CreateWASMApplication().then((Module) => {
        const soils = Module.getSoils();
        return {
            'type': ResultStatus.Success,
            "data": soils,
            // @ts-ignore
            "message": "Soils Loaded"
        };
    });
}

export async function GetSoil(soil_name: string): Promise<ResultMessage> {
    // @ts-ignore
    if(window.CreateWASMApplication === undefined) {
        return {
            'type': ResultStatus.Failure,
            "data": 'window.CreateWASMApplication === undefined',
            // @ts-ignore
            "message": "Scripts still loading... Please try again in a few moments. If this persists, refresh the page."
        }
    }

    // @ts-ignore
    return window.CreateWASMApplication().then((Module) => {
        const soil_details = Module.getSoil(soil_name);
        return {
            'type': ResultStatus.Success,
            "data": soil_details,
            // @ts-ignore
            "message": "Soils Loaded"
        };
    });
}


export async function GetTreeNames(): Promise<ResultMessage> {
    // @ts-ignore
    if(window.CreateWASMApplication === undefined) {
        return {
            'type': ResultStatus.Failure,
            "data": 'window.CreateWASMApplication === undefined',
            // @ts-ignore
            "message": "Scripts still loading... Please try again in a few moments. If this persists, refresh the page."
        }
    }

    function get_result(module: IModule.IBase) {
        const species_names = module.getSpeciesHumanReadable();
        return {
            'type': ResultStatus.Success,
            "data": species_names,
            // @ts-ignore
            "message": "Species Loaded"
        };
    }

    // @ts-ignore
    if (window.WASMApplcationModule !== undefined) {
        // @ts-ignore
        return get_result(window.WASMApplcationModule);
    }


    // @ts-ignore
    return window.CreateWASMApplication().then((Module) => {
        // @ts-ignore
        if (window.WASMApplcationModule === undefined) {
            // @ts-ignore
            window.WASMApplcationModule = Module;
        }
        return get_result(Module);
    });
}

export async function GetCanopyCover(config: ApplicationConfig): Promise<ResultMessage> {
    // @ts-ignore
    if(window.CreateWASMApplication === undefined) {
        return {
            'type': ResultStatus.Failure,
            "data": 'window.CreateWASMApplication === undefined',
            // @ts-ignore
            "message": "Scripts still loading... Please try again in a few moments. If this persists, refresh the page."
        }
    }

    function get_result(module: IModule.IBase) {
        if (!ContainsValidTrees(config.speciesDensity) || !ContainsValidTrees(config.treeDBH) || config.speciesDensity.reduce((a,b) => a+b ) === 0) {
            return {
                'type': ResultStatus.Failure,
                "data": {
                    data: [] 
                }
                ,
                // @ts-ignore
                "message": "Cannot calculate canopy cover with selected trees"
            };
        } 
        const output = module.CalculateCanopyCover(
            createVectorDouble(module, config.speciesDensity),
            createVectorDouble(module, config.treeDBH)            
        );

        const results: vector = {
            data: [],
            size: output.size()
        };

        for(let i = 0; i < output.size(); i++) {
            results.data.push({
                    dbh: output.get(i).dbh,
                    mean_stand_density: output.get(i).mean_stand_density,
                    canopy_cover: (output.get(i).canopy_cover * 100).toFixed(0),
            });
        }

        output.delete();
        return {
            'type': ResultStatus.Success,
            "data": results,
            // @ts-ignore
            "message": "Canopy Cover Calculated"
        };
    }

    // @ts-ignore
    if (window.WASMApplcationModule !== undefined) {
        // @ts-ignore
        return get_result(window.WASMApplcationModule);
    }


    // @ts-ignore
    return window.CreateWASMApplication().then((Module) => {
        // @ts-ignore
        if (window.WASMApplcationModule === undefined) {
            // @ts-ignore
            window.WASMApplcationModule = Module;
        }
        return get_result(Module);
    });
}


export async function GetLateralRootReinforcement(config: ApplicationConfig): Promise<ResultMessage> {
    // @ts-ignore
    if(window.CreateWASMApplication === undefined) {
        return {
            'type': ResultStatus.Failure,
            "data": 'window.CreateWASMApplication === undefined',
            // @ts-ignore
            "message": "Scripts still loading... Please try again in a few moments. If this persists, refresh the page."
        }
    }

    function get_result(module: IModule.IBase) {
        
        const output = module.CalculateLRRCurves(
            createVectorDouble(module, config.speciesDensity),
            createVectorDouble(module, config.treeDBH),
            config.slope * (Math.PI / 180) // Change to radians
        );

        const results: vector = {
            data: [],
            size: output.size()
        };

        for(let i = 0; i < output.size(); i++) {
            results.data.push({
                    dbh: output.get(i).dbh,
                    tree_density: output.get(i).tree_density,
                    mean_rr: output.get(i).mean_rr,
            });
        }

        output.delete();
        return {
            'type': ResultStatus.Success,
            "data": results,
            // @ts-ignore
            "message": "LRR Calculated"
        };
    }

    // @ts-ignore
    if (window.WASMApplcationModule !== undefined) {
        // @ts-ignore
        return get_result(window.WASMApplcationModule);
    }


    // @ts-ignore
    return window.CreateWASMApplication().then((Module) => {
        // @ts-ignore
        if (window.WASMApplcationModule === undefined) {
            // @ts-ignore
            window.WASMApplcationModule = Module;
        }
        return get_result(Module);
    });
}


export async function GetAgeDBHMap(config: ApplicationConfig): Promise<ResultMessage> {
    // @ts-ignore
    if(window.CreateWASMApplication === undefined) {
        return {
            'type': ResultStatus.Failure,
            "data": 'window.CreateWASMApplication === undefined',
            // @ts-ignore
            "message": "Scripts still loading... Please try again in a few moments. If this persists, refresh the page."
        }
    }

    function get_result(module: IModule.IBase) {
        if (!ContainsValidTrees(config.speciesDensity) || !ContainsValidTrees(config.treeDBH) || config.speciesDensity.reduce((a,b) => a+b ) === 0) {
            return {
                'type': ResultStatus.Failure,
                "data": {
                    data: []
                },
                // @ts-ignore
                "message": "Cannot calculate age map with selected trees"
            };
        } 
        const output = module.CalculateAgeDBHMap(
            createVectorDouble(module, config.speciesDensity),
            createVectorDouble(module, config.treeDBH),
            config.t0,
            config.elevation,
            config.aspectDeg,
            config.slope * (Math.PI / 180) // Change to radians
        );

        const results: vector = {
            data: [],
            size: output.size()
        };

        for(let i = 0; i < output.size(); i++) {
            results.data.push({
                    dbh: output.get(i).dbh * 100,
                    mean_age: output.get(i).mean_age
            });
        }

        output.delete();
        return {
            'type': ResultStatus.Success,
            "data": results,
            // @ts-ignore
            "message": "AgeMap Calculated"
        };
    }

    // @ts-ignore
    if (window.WASMApplcationModule !== undefined) {
        // @ts-ignore
        return get_result(window.WASMApplcationModule);
    }


    // @ts-ignore
    return window.CreateWASMApplication().then((Module) => {
        // @ts-ignore
        if (window.WASMApplcationModule === undefined) {
            // @ts-ignore
            window.WASMApplcationModule = Module;
        }
        return get_result(Module);
    });
}
