import {useEffect, useRef} from "react";
import {
    select
} from "d3";

export interface ILegendItem {
    name: string,
    clickable?: boolean,
    color: string,
    is_visible?: boolean
}

export interface ILegendProps {
    items: ILegendItem[]
    x: number,
    y: number,
    updateItems ?: (items: ILegendItem[]) => void
}


function hashCode(s: string) {
    return s.replace(/\s/g, '').replace(/\./g, '');
}

export default function Legend(props: ILegendProps) {
    const ref = useRef<SVGGElement>(null);

    useEffect(() => {
        var max_width = -1;
        function check_width(width: number) {
            max_width = ( max_width < width  ) ? width  : max_width;
        }

        if (ref.current) {
            select(ref.current).selectAll("*").remove();

            select(ref.current)
                .append("rect")
                .attr("x", 0)
                .attr("y", 0)
                .attr("ry", 15)
                .attr("rx", 15)
                .attr("height", 20 + 22 * props.items.length)
                .attr("width", 170)
                .attr("stroke", "grey")
                .style("fill", "#e3e8eb69");

            let count = 1;
            for(let item of props.items) {
                select(ref.current)
                    .append("circle")
                    .attr("cx",20)
                    .attr("cy",20 * count)
                    .attr("r", 6)
                    .style("fill", item.color);

                select(ref.current)
                    .append("text")
                    .attr("id", hashCode(item.name))
                    .attr("x", 30)
                    .attr("y", 20 * count)
                    .text(item.name)
                    .style("font-size", "15px")
                    .attr("alignment-baseline","central")
                    .call((ele) =>{
                        let bbox = ele.node()?.getBBox();
                        if (bbox !== undefined) {
                            check_width(bbox.width);
                        }
                    }).call((ele) => {
                        if (item?.is_visible === false) {
                            ele.style("text-decoration", "line-through");
                        }
                    })

                if (item.clickable) {
                    select(ref.current)
                    .on("click", function(d) {
                        if (props.updateItems !== undefined) {

                            // WE know we can update the items now. Find the item name and change it?
                            const new_items = [...props.items];

                            let color;

                            if (d.target.localName === "text") {
                                color = d.target.previousSibling.style.fill;
                            } else { // We have a circle!
                                color = d.target.style.fill;
                            }

                            for(let index in new_items) {
                                // Find the item by color?
                                if (new_items[index].color === color) {
                                    new_items[index].is_visible = !new_items[index].is_visible;
                                    break;
                                }
                            }
                            props.updateItems(new_items);
                        
                        }                       
                    });
                }

                count++;
            }
            if (max_width < 10) {
                max_width = 180;
            }
            select(ref.current).select("rect").attr("width", max_width + 40);
            
            

            //select(ref.current).append("circle").attr("cx",10).attr("cy",10).attr("r", 6).style("fill", "#69b3a2");
            //select(ref.current).append("text").attr("x", 220).attr("y", 130).text("variable A").style("font-size", "15px").attr("alignment-baseline","middle")
        }
    }, [props]);

    return <g transform={`translate(${props.x}, ${props.y})`}> <g ref={ref}/> </g>;
}