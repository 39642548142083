import React, { useEffect, ReactNode } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { NumberField } from "components/forms";
import { useSelector, useDispatch } from 'react-redux';
import { setWASMApplicationState} from 'store/wasmApplication/wasmApplication.slice';
import { RootState } from 'store';
import { useMediaQuery } from 'react-responsive';
import { Card } from "react-bootstrap";

export interface soilPropertyRowOutput {
    mean: number,
    stdDev: number,
    gamma: number,
}

export interface SoilPropertyRowProps {
  parameterName: ReactNode,
  distributionName: "densityDry" | "frictionAngle" | "cohesion",
  step: string
}

export default function SoilProperty(props: SoilPropertyRowProps) {

    
    const dispatch = useDispatch()
    const WASMApplicationState = useSelector((state: RootState) => state.WASMApplication);

    const initial_state: soilPropertyRowOutput  = {  
      mean: WASMApplicationState[props.distributionName].mean,
      stdDev: WASMApplicationState[props.distributionName].stdDev,
      gamma: WASMApplicationState[props.distributionName].gamma
    }


    const [parameters, setParameters] = React.useState<soilPropertyRowOutput>(initial_state);
    const isMobile = useMediaQuery({ query: `(max-width: 770px)` });


    useEffect(() => {
      setParameters(WASMApplicationState[props.distributionName])
    }, [WASMApplicationState, props.distributionName])

    /**
     * Update the local state while the component is changing.
     * @param event HTML Input Event
     */
    function handleTextBoxOnChange(event: React.ChangeEvent<HTMLInputElement>) {
      setParameters({
        ...parameters,
        [event.target.name]:
          event.target.value === ""
            ? event.target.value
            : parseFloat(event.target.value)
      });
    }

    /**
     * Update global state once the control is unfocused
     * @param event onBlur Event
     */
    function handleNumberFieldOnBlur(event: React.FocusEvent<HTMLInputElement> ) {
      dispatch(setWASMApplicationState({
        ...WASMApplicationState,
        [props.distributionName]: {
          mean: parameters.mean,
          stdDev: parameters.stdDev,
          gamma: parameters.gamma
        }
      }));
    }

    if (!isMobile) {
      return (
          <Row>
            <Col md={3} sm={12}>
              <p>{props.parameterName}</p>
            </Col>
            <Col md={3} sm={12}>
              <NumberField
                  controlId="mean"
                  name="mean"
                  step={props.step}
                  value={parameters.mean}
                  onChange={handleTextBoxOnChange}
                  onBlur={handleNumberFieldOnBlur}
              />
            </Col>
            <Col md={3} sm={12}>
            <NumberField
                  controlId="stdDev"
                  name="stdDev"
                  step={props.step}
                  min={0}
                  value={parameters.stdDev}
                  onChange={handleTextBoxOnChange}
                  onBlur={handleNumberFieldOnBlur}
              />
            </Col>
            <Col md={3} sm={12}>
            <NumberField
                  controlId="gamma"
                  step="0.01"
                  min={1}
                  name="gamma"
                  value={parameters.gamma}
                  onChange={handleTextBoxOnChange}
                  onBlur={handleNumberFieldOnBlur}
                  disabled={props.distributionName === "densityDry"}
              />
            </Col>
          </Row>
      );
    } else {
      return (
          <Card style={{ "marginTop": "1em" }}>
            <Card.Header>{props.parameterName}</Card.Header>
            <Card.Body>
              <Col sm={12} md={12}>
              <NumberField
                  controlId="mean"
                  name="mean"
                  label="Mean"
                  step={props.step}
                  value={parameters.mean}
                  onChange={handleTextBoxOnChange}
                  onBlur={handleNumberFieldOnBlur}
              />
              <NumberField
                  controlId="stdDev"
                  name="stdDev"
                  label="std. dev"
                  step={props.step}
                  value={parameters.stdDev}
                  onChange={handleTextBoxOnChange}
                  onBlur={handleNumberFieldOnBlur}
              />
              <NumberField
                  controlId="gamma"
                  step="0.01"
                  label="Partial Safety Factor"
                  name="gamma"
                  min={1}
                  value={parameters.gamma}
                  onChange={handleTextBoxOnChange}
                  onBlur={handleNumberFieldOnBlur}
                  disabled={props.distributionName === "densityDry"}
              />
              </Col>
            </Card.Body>
          </Card>
      );
    }
}