import './PlaceholderView.css';

function PlaceholderView() {

  return (
    <div>

      <p>Placeholder</p>
   </div>
  );
}

export default PlaceholderView;
