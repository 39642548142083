import React, { useEffect } from 'react';
import './LandslideThicknessView.css';
import { useSelector } from 'react-redux';
import GroupedBoxPlot, { IData as BoxPlotParams, IGroupedData } from 'components/charts/GroupedBoxPlot/GroupedBoxPlot';
import { RootState } from 'store';

const labels = {x: "", y: "Soil Thickness (m)"};
const legend = { names: ["Stable Landslide", "Unstable Landslide"] };

function LandslideThicknessView() {

  const results = useSelector((state: RootState) => state.WASMApplicationResult);
  const [data, setData] = React.useState<IGroupedData[]>([]);
   
  useEffect(() => {
    // from https://stackoverflow.com/questions/48719873/how-to-get-median-and-quartiles-percentiles-of-an-array-in-javascript-or-php
    // sort array ascending
    const asc = (arr: number[])  => arr.sort((a, b) => a - b);

    const quantile = (arr: number[], q: number) => {
        const sorted = asc(arr);
        const pos = (sorted.length - 1) * q;
        const base = Math.floor(pos);
        const rest = pos - base;
        if (sorted[base + 1] !== undefined) {
            return sorted[base] + rest * (sorted[base + 1] - sorted[base]);
        } else {
            return sorted[base];
        }
    };

    const q25 = (arr: number[]) => quantile(arr, .25);

    const q50 = (arr: number[]) => quantile(arr, .50);

    const q75 = (arr: number[]) => quantile(arr, .75);

    function process_results(stability_type: "r" | "s", veg_type: "FOSNoVeg" | "FOSVeg"): BoxPlotParams[] {
      const stable = [];
      const unstable = [];
  
      for(let i = 0; i < results.size; i++) {
        const val = results.data[i][stability_type][veg_type];
        const soil_thickness = results.data[i][stability_type]["soilThickness"];
  
        if (!isNaN(val)) {
          if (val >= 1) {
            stable.push(soil_thickness)
          } else {
            unstable.push(soil_thickness)
          }
        }
      }
  
      if (stable.length === 0) {
        stable.push(0);
      }
      if (unstable.length === 0) {
        unstable.push(0);
      }

      return [{
        q1: q25(stable),
        q3: q75(stable),
        max: stable.reduce((a,b) => Math.max(a, b), 0),
        min: stable.reduce((a,b) => Math.min(a, b), 0),
        median: q50(stable),
        number: stable.length
      },
      {
        q1: q25(unstable),
        q3: q75(unstable),
        max: unstable.reduce((a,b) => Math.max(a, b), 0),
        min: unstable.reduce((a,b) => Math.min(a, b), 0),
        median: q50(unstable),
        number: unstable.length
      }]
    }

    setData([{
      label: "Random Vegetation",
      values: process_results("r", "FOSVeg")
    },
    {
      label: "Random No Vegetation",
      values: process_results("r", "FOSNoVeg")
    },
    {
      label: "Deterministic Vegetation",
      values: process_results("s", "FOSVeg")
    },
    {
      label: "Deterministic No Vegetation",
      values: process_results("s", "FOSNoVeg")
    }]);

  }, [results]);

  return (
    <div>
      <GroupedBoxPlot data={data}
        labels={labels}
        legend={legend}
      />
      
        
    </div>
  );
}

export default LandslideThicknessView;
