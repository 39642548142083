import {useEffect, useRef} from "react";
import {
    select
} from "d3";

export interface IBoxProps {
    median: number,
    min: number,
    max: number,
    x: number,
    y: number,
    width: number,
    height: number,
    color: string
}

export default function Box(props: IBoxProps) {
    const ref = useRef<SVGGElement>(null);
    const line_width = 1;
    const radius_corners = 15;

    useEffect(() => {
        if (ref.current) {

            const middle_point = props.x + props.width/2;
            
            select(ref.current).selectAll("*").remove();

            // The main box
            select(ref.current).append("rect")
                .attr("x", props.x)
                .attr("y", props.y)
                .attr("ry", radius_corners)
                .attr("rx", radius_corners)
                .attr("height", props.height)
                .attr("width", props.width)
                .attr("stroke", "grey")
                .style("fill", props.color);

            /**
             * MAX LINE
             */
            select(ref.current)
                .append('line')
                .style("stroke", "black")
                .style("stroke-width", line_width)
                .attr("x1", middle_point)
                .attr("y1", props.y)
                .attr("x2", middle_point)
                .attr("y2", props.max); 
            select(ref.current)
                .append('line')
                .style("stroke", "black")
                .style("stroke-width", line_width)
                .attr("x1", middle_point - props.width/3)
                .attr("y1", props.max)
                .attr("x2", middle_point + props.width/3)
                .attr("y2", props.max);


            /**
             * MIN LINE
             */
            select(ref.current)
                .append('line')
                .style("stroke", "black")
                .style("stroke-width", line_width)
                .attr("x1", middle_point)
                .attr("y1", props.y + props.height)
                .attr("x2", middle_point)
                .attr("y2", props.min); 
            select(ref.current)
                .append('line')
                .style("stroke", "black")
                .style("stroke-width", line_width)
                .attr("x1", middle_point - props.width/3)
                .attr("y1", props.min)
                .attr("x2", middle_point + props.width/3)
                .attr("y2", props.min);

             /**
             * MEDIAN LINE
             */
              select(ref.current)
              .append('line')
              .style("stroke", "black")
              .style("stroke-width", line_width)
              .attr("x1", props.x)
              .attr("y1", props.median)
              .attr("x2", props.x + props.width)
              .attr("y2", props.median); 
            
        }
    }, [props.width, props.height, props.median, props.min, props.max, props.color, props.x, props.y]);

    return <g ref={ref}/>;
}